import { apiCall } from "./servicesCall";

export const STAFF_SERVICES = {
  getStaff(data) {
    return apiCall("/staff", data);
  },
  createStaff(data) {
    return apiCall("/staff/create", data);
  },
  deleteStaff(data) {
    return apiCall("/staff/delete", data);
  },
  changeStatus(data) {
    return apiCall("/staff/change/status", data);
  },
  editStaff(data) {
    return apiCall("/staff/edit", data);
  },
  updatStaff(data) {
    return apiCall("/staff/update", data);
  },
  getListStaff() {
    return apiCall("/staff/list");
  },
};
