import React, { useEffect, useState } from "react";
import DataTable from "../../Table/DataTable";
import AddIcon from "@mui/icons-material/Add";
import AddNewRole from "./AddNewRole";
import EditRole from "./EditRole";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { Box } from "@mui/material";
import { ROLE_SERVICES } from "../../../services";
import { ToastManager } from "../../common/ToastContainer";
import Moment from "moment";
import FullPageProgress from "../../common/FullPageProgress";
import { PERMISSION_LIST, USER_STATUS_LIST } from "../../../helpers/constants";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";

export default function ManageRoles(props) {
  const { module } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [messageConfirm, setMessageConfirm] = useState();
  const [titleConfirm, setTitleConfirm] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmRequestData, setConfirmRequestData] = useState();
  const [openEditRole, setOpeneditRole] = useState(false);

  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();

  const columns = [
    { name: "sno", label: "S.No." },
    { name: "name", label: "Role" },
    { name: "permission", label: "Permission" },
    { name: "createdAt", label: "Created On" },
    { name: "lastupdatedAt", label: "Last Updated" },
    { name: "lastupdatedBy", label: "Updated By" },
    { name: "statusName", label: "Status" },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        display: modulePermissions?.edit || modulePermissions?.delete,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <span className="">
              {modulePermissions?.edit && (
                <button
                  className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-medium"
                  onClick={() => editRole(dataIndex)}
                >
                  Edit
                </button>
              )}
              {modulePermissions?.delete && (
                <button
                  className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-medium m-l-10"
                  onClick={() => {
                    roleConfirmDelete(dataIndex);
                  }}
                >
                  Delete
                </button>
              )}{" "}
              {modulePermissions?.edit && (
                <>
                  {roleList?.[dataIndex].status ===
                  USER_STATUS_LIST?.active?.value ? (
                    <button
                      className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-medium m-l-10"
                      onClick={() => {
                        roleConfirmStatus(
                          dataIndex,
                          USER_STATUS_LIST?.suspend?.value
                        );
                      }}
                    >
                      {USER_STATUS_LIST?.suspend?.name}
                    </button>
                  ) : roleList?.[dataIndex].status ===
                    USER_STATUS_LIST.suspend.value ? (
                    <button
                      className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-medium m-l-10"
                      onClick={() => {
                        roleConfirmStatus(
                          dataIndex,
                          USER_STATUS_LIST?.active?.value
                        );
                      }}
                    >
                      {USER_STATUS_LIST.active.name}
                    </button>
                  ) : null}
                </>
              )}
            </span>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions.view) return;

    setLoader(true);
    setDataFetched(true);
    ROLE_SERVICES.getRole({ userId: userData.userId })
      .then((data) => {
        if (data.code === 200) {
          setRoleList(data?.data || []);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, modulePermissions]);

  const getRoleList = () => {
    setDataFetched(false);
  };

  const roleConfirmStatus = async (dataIndex, type) => {
    let item = roleList?.[dataIndex];
    if (item?.id) {
      setSelectedRole(item);
      setConfirmRequestData({
        action: "status",
        status: USER_STATUS_LIST?.[type]?.value,
      });
      confirmRequest(
        "Role : " + item?.name,
        ` Are You sure for ${USER_STATUS_LIST?.[type]?.name}?`
      );
    }
  };

  const editRole = async (dataIndex) => {
    let item = roleList?.[dataIndex];
    if (item?.id) {
      setSelectedRole(item);
      setOpeneditRole(true);
    }
  };

  const confirmRequest = (title, message) => {
    setOpenConfirm(true);
    setTitleConfirm(title);
    setMessageConfirm(message);
  };
  const roleConfirmDelete = async (dataIndex) => {
    let item = roleList?.[dataIndex];
    if (item?.id) {
      setSelectedRole(item);
      confirmRequest("Role : " + item?.name, " Are You sure for delete?");
      setConfirmRequestData({ action: "delete" });
    }
  };

  const confirmRequestCancel = () => {
    setOpenConfirm(false);
    setSelectedRole("");
    setConfirmRequestData("");
  };

  const confirmRequestAccept = async () => {
    setOpenConfirm(false);
    if (selectedRole?.id) {
      if (
        confirmRequestData?.action === "delete" &&
        modulePermissions?.delete
      ) {
        setLoader(true);
        try {
          let parma = { id: selectedRole?.id, userId: userData.userId };
          const data = await ROLE_SERVICES.deleteRole(parma);
          if (data.code === 200) {
            ToastManager.addToast(data.message);
            getRoleList();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
          }
        } catch (error) {
          ToastManager.addToast(error.message);
        }
        setLoader(false);
      } else if (
        confirmRequestData?.action === "status" &&
        confirmRequestData?.status &&
        modulePermissions?.edit
      ) {
        setLoader(true);
        try {
          let parma = {
            id: selectedRole?.id,
            status: confirmRequestData?.status,
            userId: userData.userId,
          };

          const data = await ROLE_SERVICES.changeStatus(parma);
          if (data.code === 200) {
            ToastManager.addToast(data.message);
            getRoleList();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
          }
        } catch (error) {
          ToastManager.addToast(error.message);
        }
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  return (
    <>
      {loader && <FullPageProgress />}
      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              Manage Roles
            </div>
          </div>
          {modulePermissions?.add && (
            <div className="managepro6ix-layout-page-content-header-action">
              <button
                className="managepro6ix-btn managepro6ix-btn-primary"
                onClick={() => setOpen(true)}
              >
                <AddIcon className="m-r-5" /> Add new
              </button>
            </div>
          )}
        </div>

        <DataTable
          columns={columns}
          data={roleList.map((item, key) => {
            return {
              sno: key + 1,
              name: item.name,
              permission: item.permission
                ? Object.keys(item.permission).map(
                    (option, index) =>
                      (index > 0 ? ", " : "") +
                      (PERMISSION_LIST?.[option]?.name || "")
                  )
                : "",
              createdAt: Moment(item?.createdAt).format(
                "MMM DD, YYYY : hh:mm A"
              ),
              lastupdatedAt: Moment(item?.updatedBy?.date).format(
                "MMM DD, YYYY : hh:mm A"
              ),
              lastupdatedBy: item?.updatedBy?.name,
              statusName: USER_STATUS_LIST?.[item?.status]?.name,
              id: item?.id,
            };
          })}
        />
      </Box>
      {open && modulePermissions?.add && (
        <AddNewRole open={open} setOpen={setOpen} update={getRoleList} />
      )}

      {openEditRole && selectedRole && modulePermissions?.edit && (
        <EditRole
          update={getRoleList}
          open={openEditRole}
          setOpen={setOpeneditRole}
          role={selectedRole}
        />
      )}

      <ConfirmModal
        title={titleConfirm}
        message={messageConfirm}
        request={openConfirm}
        cancel={confirmRequestCancel}
        accept={confirmRequestAccept}
      />
    </>
  );
}
