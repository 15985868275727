import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SESSION_AUTH } from "../../../helpers/auth";
import EventFunnel from "./EventFunnel";
import NewUser from "./NewUser";
import EventDistribution from "./EventDistribution";
import EventHost from "./EventHost";
import EventLive from "./EventLive";
import EventParticipant from "./EventParticipant";
import EventInvitations from "./EventInvitations";
import EventReplay from "./EventReplay";
import EventReplayViews from "./EventReplayViews";
import EventAttendes from "./EventAttendes";
import EventAttendesAverage from "./EventAttendesAverage";
import EventAttendesJoin from "./EventAttendesJoin";

export default function AnalyticsEvent() {
  const navigate = useNavigate();
  const { tabType } = useParams();
  const { userSession } = SESSION_AUTH();

  useEffect(() => {
    if (!userSession) {
      navigate("/");
    }
    return () => null;
  }, [userSession, navigate]);

  return (
    <>
      {tabType === "users" ? (
        <NewUser />
      ) : tabType === "event-distribution" ? (
        <EventDistribution />
      ) : tabType === "event-host" ? (
        <EventHost />
      ) : tabType === "event-participant" ? (
        <EventParticipant />
      ) : tabType === "event-live" ? (
        <EventLive />
      ) : tabType === "event-invitation" ? (
        <EventInvitations />
      ) : tabType === "event-replay" ? (
        <EventReplay />
      ) : tabType === "event-replay-views" ? (
        <EventReplayViews />
      ) : tabType === "event-attendes" ? (
        <EventAttendes />
      ) : tabType === "event-attendes-average" ? (
        <EventAttendesAverage />
      ) : tabType === "event-attendes-join" ? (
        <EventAttendesJoin />
      ) : (
        <EventFunnel />
      )}
    </>
  );
}
