import { apiCall } from "./servicesCall";

export const USER_SERVICES = {
  getUser(data) {
    return apiCall("/user", data);
  },
  loginUser(data) {
    return apiCall("/user/login", data);
  },
  logoutUser(data) {
    return apiCall("/user/logout", data);
  },
  editUser(data) {
    return apiCall("/user/edit", data);
  },
  updateUser(data) {
    return apiCall("/user/update", data);
  },
};
