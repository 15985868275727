import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { ToastManager } from "./components/common/ToastContainer";
import { USER_SERVICES } from "./services";

const firebaseConfig = {
  apiKey: "AIzaSyB60MQdY9DDQKuaz0aP96TOip1ICbt9iAU",
  authDomain: "authentication.6ix.com",
  projectId: "ix-managepro",
  storageBucket: "ix-managepro.appspot.com",
  messagingSenderId: "1006606064259",
  appId: "1:1006606064259:web:6639cd9eb9489b5b3a19b6",
  measurementId: "G-23TK8RJQ02",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
getAnalytics(app);

const googleProvider = new GoogleAuthProvider({
  prompt: "select_account",
});

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    if (auth.currentUser) {
      const user = auth.currentUser;
      const DATA = {
        tokenID: user.accessToken,
        email: user.email,
      };

      let data = await USER_SERVICES.loginUser(DATA);
      if (data.code === 200) {
        let d = data.data;
        d["uid"] = user?.uid;
        ToastManager.addToast("You are now logged in");
        return { code: 200, data: d };
      } else {
        ToastManager.addToast(data.message);
        return false;
      }
    } else {
      ToastManager.addToast("Error:  Login faild");
      return false;
    }
  } catch (err) {
    ToastManager.addToast(errorSplit(err.message));
    return false;
  }
};

const signInWithUserEmailLink = async (email) => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        ToastManager.addToast(
          "An email with the link has been sent to " + email
        );
        return "An email with the link has been sent to " + email;
      })
      .catch((err) => {
        ToastManager.addToast(errorSplit(err.message));
        return false;
      });
  } catch (err) {
    ToastManager.addToast(errorSplit(err.message));
    return false;
  }
};
const signInWithUserEmailLinkVerify = (email) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }

    return signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        if (auth.currentUser) {
          const user = auth.currentUser;
          const DATA = {
            tokenID: user.accessToken,
            email: user.email,
          };

          let data = await USER_SERVICES.loginUser(DATA);
          if (data.code === 200) {
            let d = data.data;
            d["uid"] = user?.uid;
            ToastManager.addToast("You are now logged in");
            return { code: 200, data: d };
          } else {
            ToastManager.addToast(data.message);
            return false;
          }
        } else {
          ToastManager.addToast("Error:  Login faild");
          return false;
        }
      })
      .catch((err) => {
        ToastManager.addToast(errorSplit(err.message));
        return false;
      });
  } else {
    ToastManager.addToast("Error:  Something went wrong");
    return false;
  }
};

const userLogout = (user) => {
  signOut(auth);
  if (user?.userId) {
    USER_SERVICES.logoutUser({
      userId: user?.userId,
    });
  }
};

const errorSplit = (error) => {
  if (error) {
    if (error.indexOf("/") > -1) {
      error = error.split("/")[1];
      if (error.indexOf(")") > -1) {
        error = error.split(")")[0];
      }
      error = "Error : " + error;
    } else if (error.indexOf("Firebase:") > -1) {
      error = error.split("Firebase:")[1];
    }
  }
  return error;
};

export {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
  signInWithUserEmailLinkVerify,
  userLogout,
};
