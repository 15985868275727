import AccessControl from "./helpers/accessControl";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import { AnalyticsPage } from "./pages/AnalyticsPage";
import {
  EventsPage,
  EventsUsersPage,
  EventsUsersProfilePage,
  EventsTransitionsPage,
  EventsListPage,
  EventsOrgazinationPage,
  EventsOrgazinationTransitionPage,
} from "./pages/EventsPage";
import { PromoPage } from "./pages/PromoPage";
import { PollsPage, PollsUsersPage } from "./pages/PollsPage";
import { AiPage, AiConversationPage, AiTopicPage } from "./pages/AiPage";
import { ManageRolesPage, ManageStaffPage } from "./pages/ManagePage";
import InternalLayout from "./Layout";

import { AppToastContainer } from "./components/common/ToastContainer";
import { AuthPage } from "./pages/AuthPage";

function App() {
  return (
    <BrowserRouter>
      <AppToastContainer />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/auth" element={<AuthPage />} />

        <Route element={<InternalLayout />}>
          <Route
            path="/"
            element={
              <AccessControl>
                <DashboardPage module="dashboard" />
              </AccessControl>
            }
          />

          <Route
            path="analytics/:tabType?"
            element={
              <AccessControl>
                <AnalyticsPage module="dashboard" />
              </AccessControl>
            }
          />

          <Route path="/events">
            <Route path="users">
              <Route
                path=":userId/:tabType?"
                element={
                  <AccessControl>
                    <EventsUsersProfilePage module="events" />
                  </AccessControl>
                }
              />
              <Route
                path=""
                element={
                  <AccessControl>
                    <EventsUsersPage module="events" />
                  </AccessControl>
                }
              />
            </Route>
            <Route
              path="transactions"
              element={
                <AccessControl>
                  <EventsTransitionsPage module="events" />
                </AccessControl>
              }
            />
            <Route
              path="list"
              element={
                <AccessControl>
                  <EventsListPage module="events" />
                </AccessControl>
              }
            />

            <Route path="organization">
              <Route
                path=":accountId/transactions"
                element={
                  <AccessControl>
                    <EventsOrgazinationTransitionPage module="events" />
                  </AccessControl>
                }
              />
              <Route
                path=""
                element={
                  <AccessControl>
                    <EventsOrgazinationPage module="events" />
                  </AccessControl>
                }
              />
            </Route>

            <Route
              path=""
              element={
                <AccessControl>
                  <EventsPage module="events" />
                </AccessControl>
              }
            />
          </Route>

          <Route path="/ai">
            <Route
              path="topics"
              element={
                <AccessControl>
                  <AiTopicPage module="ai" />
                </AccessControl>
              }
            />
            <Route
              path="conversation/:topic"
              element={
                <AccessControl>
                  <AiConversationPage module="ai" />
                </AccessControl>
              }
            />
            <Route
              path=""
              element={
                <AccessControl>
                  <AiPage module="ai" />
                </AccessControl>
              }
            />
          </Route>

          <Route path="/promo">
            <Route path="" element={<PromoPage />} />
          </Route>

          <Route path="/polls" element={<PollsPage />}>
            <Route path="users" element={<PollsUsersPage />} />
          </Route>
          <Route path="/manage">
            <Route
              path="roles"
              element={
                <AccessControl>
                  <ManageRolesPage module="manage" />
                </AccessControl>
              }
            />
            <Route
              path="staff"
              element={
                <AccessControl>
                  <ManageStaffPage module="manage" />
                </AccessControl>
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
