import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { SESSION_AUTH } from "../../helpers/auth";
import { DASHBOARD_SERVICES } from "../../services";
import { ToastManager } from "../common/ToastContainer";
import FullPageProgress from "../common/FullPageProgress";

export default function Dashboard() {
  const navigate = useNavigate();
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState("");
  const dataFetched = useRef();
  const [loader, setLoader] = useState(true);
  const [eventsData, setEventsData] = useState("");

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [userSession]);

  useEffect(() => {
    if (!userData || dataFetched.current) return;

    setLoader(true);
    dataFetched.current = true;
    DASHBOARD_SERVICES.getEvent({ userId: userData.userId })
      .then((data) => {
        if (data.code === 200) {
          setEventsData(data?.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION]);

  if (loader) {
    return <FullPageProgress />;
  }
  return (
    <>
      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              6ix Dashboard
            </div>
          </div>
        </div>
        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                marginTop: "10px",
                backgroundColor: "#927A64",
                color: "#fff",
                textAlign: "left",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#927a64e6",
                },
              }}
              onClick={() => navigate("/events/users")}
            >
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.white"
                  fontWeight={600}
                  letterSpacing={1}
                >
                  USERS (TOTAL)
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginTop={6}
                >
                  {eventsData?.user?.count || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                marginTop: "10px",
                backgroundColor: "#60574D",
                color: "#fff",
                textAlign: "left",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#60574de6",
                },
              }}
              onClick={() => navigate("/events/organization")}
            >
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.white"
                  fontWeight={600}
                  letterSpacing={1}
                >
                  ORGANIZATIONS (TOTAL)
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginTop={6}
                >
                  {eventsData?.orgazination?.count || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                marginTop: "10px",
                backgroundColor: "#575757",
                color: "#fff",
                textAlign: "left",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#575757e6",
                },
              }}
              onClick={() => navigate("/events/list")}
            >
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.white"
                  fontWeight={600}
                  letterSpacing={1}
                >
                  EVENTS (TOTAL)
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  marginTop={6}
                >
                  {eventsData?.event?.count || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
