import React, { useEffect, useState } from "react";
import { TablePagination } from "@mui/material";
import Moment from "moment";
import DataTable from "../../../Table/DataTable";

import { useParams } from "react-router-dom";
import { EVENTS_SERVICES } from "../../../../services";
import { ToastManager } from "../../../common/ToastContainer";
import FullPageProgress from "../../../common/FullPageProgress";
import { SESSION_AUTH } from "../../../../helpers/auth";
import ShowSpeakersModal from "../../../Modals/ShowSpeakersModal";

const EventUserSpeakers = () => {
  const [loader, setLoader] = useState(false);
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState("");
  const [eventList, setEventList] = useState([]);
  const { userId } = useParams();
  const [openSpeakerModal, setOpenSpeakerModal] = useState(false);
  const [speakerData, setSpeakerData] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const defaultFilter = {
    page: 0,
    limit: 10,
    speakerId: userId,
  };
  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [userSession]);

  useEffect(() => {
    if (!userData || dataFetched) return;

    setLoader(true);
    setDataFetched(true);

    let parma = { ...filter };
    parma["userId"] = userData.userId;

    EVENTS_SERVICES.getEvents(parma)
      .then((data) => {
        setEventList([]);
        if (data.code === 200) {
          setEventList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, filter]);

  const columns = [
    {
      name: "sno",
      label: "S.No.",
    },
    {
      name: "eventName",
      label: "Event Name",
    },
    {
      name: "owner",
      label: "Owner",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{value?.name}</span>;
        },
      },
    },
    {
      name: "eventDate",
      label: "Event Date",
    },
    {
      name: "createdAt",
      label: "Created On",
    },

    {
      name: "eventStatus",
      label: "Current Status",
      options: {
        customBodyRender: (value) => {
          return <span className="text-status">{value}</span>;
        },
      },
    },
    {
      name: "eventType",
      label: "Type",
      options: {
        customBodyRender: (value) => {
          return value?.eventType;
        },
      },
    },
    {
      name: "dialinPin",
      label: "Dial-in Pin",
    },
    {
      name: "totalRegister",
      label: "Total Registrants",
    },
    {
      name: "totalAttends",
      label: "Total Attendes",
    },
    {
      name: "speakers",
      label: "Speakers",
      options: {
        customBodyRender: (speakers) => {
          return speakers?.length > 0 ? (
            <span
              className="text-link"
              onClick={() => {
                setOpenSpeakerModal(true);
                setSpeakerData(speakers);
              }}
            >
              {speakers?.length}
            </span>
          ) : (
            <>-</>
          );
        },
      },
    },
    {
      name: "interestedMeeting",
      label: "Meeting Request",
    },
    {
      name: "meetingComplete",
      label: "Meeting Complete",
    },

    {
      name: "pageviewsregistere",
      label: "Register Pageviews",
    },
    {
      name: "registrants",
      label: "Registrants",
    },
    {
      name: "pageviewsattendee",
      label: "Attendee Pageviews",
    },
    {
      name: "attendees",
      label: "Attendees",
    },
    {
      name: "pageviewsreplay",
      label: "Replay Pageviews",
    },
    {
      name: "getreplays",
      label: "Get the Replay",
    },
    {
      name: "pageviewsondemand",
      label: "On-Demand Pageviews",
    },
    {
      name: "ondemands",
      label: "On-Demand",
    },
    {
      name: "pageviewstotal",
      label: "Total Pageviews",
    },
    {
      name: "pageviewslast",
      label: "Last Pageviews",
    },

    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (item) => {
          return (
            <a
              href={item?.eventMeetingLink}
              target="_blank"
              className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
              rel="noreferrer"
            >
              View
            </a>
          );
        },
      },
    },
  ];

  const handleChangePage = (event, newPage) => {
    setFilter((old) => ({
      ...old,
      page: newPage,
    }));
    setDataFetched(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter((old) => ({
      ...old,
      limit: event.target.value,
      page: 0,
    }));
    setDataFetched(false);
  };

  const customOption = {
    pagination: false,
  };

  return (
    <>
      {loader && <FullPageProgress />}

      <DataTable
        columns={columns}
        customOption={customOption}
        data={eventList.map((item, key) => {
          return {
            sno: filter?.page * filter?.limit + key + 1,
            eventName: item.eventName,
            eventType: item,
            owner: item.owner,
            eventDate: item?.eventDate
              ? Moment(item?.eventDate).format("MMM DD, YYYY : hh:mm A")
              : "TBD",
            createdAt: Moment(item?.createdAt).format("MMM DD, YYYY : hh:mm A"),

            eventStatus: item.eventStatus,
            action: item,
            totalAttends: item.totalAttends,
            totalRegister: item.totalRegister,
            speakers: item.speakers,
            interestedMeeting: item.interestedMeeting,
            meetingComplete: item.meetingComplete,
            irmTicket: { eventId: item?._id, link: item?.irmTicket?.link },
            dialinPin: item?.dialin?.pin || "-",
            id: item?._id,
            pageviewsregistere: item?.views?.register || "-",
            pageviewsattendee: item?.views?.attendee || "-",
            pageviewsreplay: item?.views?.replay || "-",
            pageviewsondemand: item?.views?.ondemand || "-",
            pageviewstotal: item?.views?.total || "-",
            pageviewslast: item?.views?.date
              ? Moment(item?.views?.date).format("MMM DD, YYYY : hh:mm A")
              : "-",
            registrants: item?.registrants,
            attendees: item?.attendees,
            getreplays: item?.getreplays,
            ondemands: item?.ondemands,
          };
        })}
      />

      <TablePagination
        component="div"
        count={totalRows}
        page={filter.page}
        onPageChange={handleChangePage}
        rowsPerPage={filter.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={true}
        showLastButton={true}
      />
      {openSpeakerModal && (
        <ShowSpeakersModal
          open={openSpeakerModal}
          setOpen={setOpenSpeakerModal}
          data={speakerData}
        />
      )}
    </>
  );
};

export default EventUserSpeakers;
