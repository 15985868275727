import React from "react";
import { Box } from "@mui/material";
import AnalyticsEvent from "../components/Analytics/Event/AnalyticsEvent";

export const AnalyticsPage = () => {
  return (
    <Box className="managepro6ix-layout-page">
      <AnalyticsEvent />
    </Box>
  );
};

export default AnalyticsPage;
