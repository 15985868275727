import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";

import { USER_STATUS_LIST } from "../../../helpers/constants";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";
import { ROLE_SERVICES, STAFF_SERVICES } from "../../../services";
import { SESSION_AUTH } from "../../../helpers/auth";
export default function EditStaff(props) {
  const { setOpen, open, staff, update } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();

  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [position, setPosition] = useState("");
  const [status, setStatus] = useState("");
  const [roleList, setRoleList] = useState([]);
  const dataFetchedRef = useRef(false);
  const [loader, setLoader] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (staff?.id) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      STAFF_SERVICES.editStaff({ id: staff?.id, userId: userSession?.userId })
        .then((data) => {
          if (data.code === 200) {
            setName(data?.data.name);
            setEmail(data?.data.email);
            setRole(data?.data.role);
            setPhoneNumber(data?.data.phoneNumber);
            setPosition(data?.data.position);
            setStatus(data?.data.status);
            getROleList();
          } else if (data.code === 600) {
            return LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
          }
        })
        .catch((error) => {
          ToastManager.addToast(error.message);
        })
        .finally(() => {
          setLoader(false);
        });

      const getROleList = () => {
        ROLE_SERVICES.getRoleList({ userId: userSession?.userId })
          .then((data) => {
            if (data.code === 200) {
              setRoleList(data?.data || []);
            } else if (data.code === 600) {
              return LOGOUT_USER_SESSION();
            } else {
              ToastManager.addToast(data?.message);
            }
          })
          .catch((error) => {
            ToastManager.addToast(error.message);
          });
      };
    }
    return () => null;
  }, [staff, userSession, LOGOUT_USER_SESSION]);

  const updateStaff = async () => {
    if (checkFormFields()) {
      try {
        let parma = {
          userId: userSession?.userId,
          name,
          role,
          phoneNumber,
          position,
          status,
          id: staff?.id,
        };
        const data = await STAFF_SERVICES.updatStaff(parma);
        if (data.code === 200) {
          ToastManager.addToast(data.message);
          update();
          handleClose();
        } else if (data.code === 600) {
          return LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast(data?.message);
        }
      } catch (error) {
        ToastManager.addToast(error.message);
      }
    }
  };
  const checkFormFields = () => {
    if (!name) {
      ToastManager.addToast("Please fill  name");
      return false;
    } else if (!role) {
      ToastManager.addToast("Please choose  role");
      return false;
    } else if (!email) {
      ToastManager.addToast("Please fill  email");
      return false;
    } else if (!phoneNumber) {
      ToastManager.addToast("Please fill  phone number");
      return false;
    } else if (!position) {
      ToastManager.addToast("Please fill  position");
      return false;
    } else if (!status) {
      ToastManager.addToast("Please choose account status");
      return false;
    }

    return true;
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog"
    >
      <DialogTitle className="text-center">Edit Staff</DialogTitle>
      <DialogContent>
        {loader && <FullPageProgress />}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <TextField
              type="text"
              xs={6}
              label="Name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="role"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                {roleList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <TextField
              type="email"
              xs={6}
              label="Email"
              name="email"
              value={email}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <TextField
              type="tel"
              xs={6}
              label="Phone number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <TextField
              type="text"
              xs={6}
              label="Position"
              name="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="status"
                name="status"
                value={status}
                inputProps={{ readOnly: true }}
              >
                {Object.keys(USER_STATUS_LIST).map((item, index) => (
                  <MenuItem key={index} value={USER_STATUS_LIST[item].value}>
                    {USER_STATUS_LIST[item].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <button
          type="button"
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-primary"
          onClick={updateStaff}
        >
          Update Staff
        </button>
        <button
          onClick={() => {
            setOpen(false);
          }}
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-secondary"
        >
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  );
}
