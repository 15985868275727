import React from "react";
import { Box } from "@mui/material";
import LayoutHeader from "./LayoutHeader";
import { Outlet } from "react-router-dom";
import { SESSION_AUTH } from "../helpers/auth";

const InternalLayout = () => {
  const { userSession } = SESSION_AUTH();

  return (
    <Box className="managepro6ix-layout " sx={{ display: "flex" }}>
      {userSession && <LayoutHeader />}
      <Outlet />
    </Box>
  );
};

export default InternalLayout;
