import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import "react-tagsinput/react-tagsinput.css";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";
import { EVENTS_SERVICES } from "../../../services";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { EVENT_TYPE_LIST } from "../../../helpers/constants";

export default function EditEventTypeModal(props) {
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { onUpdate, setOpen, open, event, module } = props;
  const [modulePermissions, setModulePermissions] = useState();
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  const [eventType, setEventType] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (userData && !modulePermissions?.add) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  useEffect(() => {
    setEventType(event?.eventType || "");
  }, [event]);

  const handleClose = () => {
    setOpen(false);
  };

  const saveEvent = async () => {
    if (!eventType) {
      ToastManager.addToast("Please choose event type");
      return false;
    }
    setLoader(true);

    try {
      let parma = {
        userId: userSession?.userId,
        eventId: event?._id,
        eventType: eventType,
      };
      const data = await EVENTS_SERVICES.updateEventType(parma);
      if (data.code === 200) {
        ToastManager.addToast(data.message);
        onUpdate();
        handleClose();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast(data?.message);
      }
    } catch (error) {
      ToastManager.addToast(error.message);
    }
    setLoader(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog custom-dialog-full"
    >
      <DialogTitle className="text-center">
        Event Type <br /> <small> {event?.eventName}</small>
      </DialogTitle>
      <DialogContent>
        {loader && <FullPageProgress />}

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 10 }}>
            <FormControl fullWidth>
              <InputLabel>Event Type</InputLabel>
              <Select
                label="eventType"
                name="eventType"
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
              >
                {Object.keys(EVENT_TYPE_LIST).map((item, index) => (
                  <MenuItem key={index} value={EVENT_TYPE_LIST[item].value}>
                    {EVENT_TYPE_LIST[item].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <button
          type="button"
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-primary"
          onClick={saveEvent}
        >
          Submit
        </button>
        <button
          onClick={handleClose}
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-secondary"
        >
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  );
}
