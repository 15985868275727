import { Box, CircularProgress } from "@mui/material";
const FullPageProgress = () => (
  <Box
    style={{
      width: "100%",
      height: "100vh",
      position: "fixed",
      left: "0px",
      top: "0px",
      zIndex: 999,
    }}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress size={60} />
  </Box>
);

export default FullPageProgress;
