import React from "react";
import { Box } from "@mui/material";
import EventDashboard from "../components/Event/EventDashboard";
import EventUser from "../components/Event/EventUser";
import EventUserTabs from "../components/Event/EventUser/Profile";
import EventTransitions from "../components/Event/Transitions";
import EventList from "../components/Event/EventList/EventList";
import EventOrgazination from "../components/Event/EventOrgazination";
import EventOrgazinationTransition from "../components/Event/EventOrgazination/Transition";

export const EventsPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <EventDashboard module={module} />
    </Box>
  );
};

export const EventsUsersPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <EventUser module={module} />
    </Box>
  );
};
export const EventsUsersProfilePage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <EventUserTabs module={module} />
    </Box>
  );
};

export const EventsTransitionsPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <EventTransitions module={module} />
    </Box>
  );
};

export const EventsListPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <EventList module={module} />
    </Box>
  );
};
export const EventsOrgazinationPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <EventOrgazination module={module} />
    </Box>
  );
};
export const EventsOrgazinationTransitionPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <EventOrgazinationTransition module={module} />
    </Box>
  );
};

export default EventsPage;
