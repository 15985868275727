import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export function ConfirmModal(props) {
  const { request, cancel, accept, title, message } = props;
  return (
    <Dialog
      open={request}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog"
    >
      <DialogTitle className="text-center">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          className="text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        ></DialogContentText>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <button
          onClick={accept}
          autoFocus
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-primary"
        >
          Yes
        </button>
        <button
          onClick={cancel}
          autoFocus
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-secondary"
        >
          No, thanks
        </button>
      </DialogActions>
    </Dialog>
  );
}
