import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
} from "@mui/material";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { saveAs } from "file-saver";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";
import { EVENTS_SERVICES } from "../../../services";
import { SESSION_AUTH } from "../../../helpers/auth";
import {
  SAMPLE_CSV_PARTICIPANT,
  CSVToJSONConvert,
  isValidEmail,
  getModuleAllPermissions,
} from "../../../helpers/utils";

export default function AddNewParticipant(props) {
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { setOpen, open, event, module } = props;
  const [modulePermissions, setModulePermissions] = useState();
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  const [csvData, setCsvData] = useState("");
  const [loader, setLoader] = useState(false);
  const [emails, setEmails] = useState([]);
  const [inviteCSV, setInviteCSV] = useState("");

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (userData && !modulePermissions?.add) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  const handleClose = () => {
    setOpen(false);
  };

  const addNewStaff = async () => {
    if (emails?.length <= 0 && csvData?.length <= 0) {
      ToastManager.addToast("Please enter email Or Attach CSV");
      return false;
    }
    setLoader(true);
    let requetData = [];
    if (csvData?.length > 0) {
      requetData = csvData;
    } else if (emails?.length > 0) {
      requetData = emails.map((elem) => ({
        email: elem,
      }));
    }

    try {
      let parma = {
        userId: userSession?.userId,
        participant: requetData,
        eventId: event?._id,
      };
      const data = await EVENTS_SERVICES.addParticipant(parma);
      if (data.code === 200) {
        ToastManager.addToast(data.message);
        handleClose();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast(data?.message);
      }
    } catch (error) {
      ToastManager.addToast(error.message);
    }
    setLoader(false);
  };

  const donwloadSampleInvite = () => {
    saveAs(SAMPLE_CSV_PARTICIPANT, "sample-invite-emails.csv");
  };

  const addEmails = (tags) => {
    setCsvData([]);
    setInviteCSV("");
    let unique = [...new Set(tags)];
    unique = unique.filter(
      (notUndefined) => notUndefined !== undefined && isValidEmail(notUndefined)
    );
    setEmails(unique);
  };

  const onSelectCSV = (e) => {
    setCsvData([]);
    setEmails([]);

    if (!e.target.files || e.target.files.length === 0) {
      setInviteCSV("");
      return;
    }
    let file = e.target.files[0];
    setInviteCSV(file);

    setLoader(true);
    try {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        if (event?.target?.result) {
          const csvData = CSVToJSONConvert(event.target.result);
          if (csvData && csvData.length > 0) {
            let email = csvData
              .map((elem) => ({ email: elem.email_id, name: elem.name }))
              .filter(
                (notUndefined) =>
                  notUndefined.email !== undefined &&
                  isValidEmail(notUndefined.email)
              );
            if (email?.length > 1000) {
              ToastManager.addToast(
                "Max records limit is 1000, upload upto 1000 records CSV"
              );
            } else {
              console.log(email, "email");
              const unique = Object.values(
                email.reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {})
              );

              if (unique?.length <= 0) {
                ToastManager.addToast(
                  "Formatting error, please correct as per the sample CSV"
                );
              } else if (unique.length > 1000) {
                ToastManager.addToast("Max entry limit 1000");
              } else {
                setCsvData(unique);
              }
            }
          }
        } else {
          ToastManager.addToast(
            "Formatting error, please correct as per the sample CSV2"
          );
        }
        setLoader(false);
      };
      fileReader.readAsText(file);
    } catch (err) {
      ToastManager.addToast({
        title: "Formatting error, please correct as per the sample CSV",
      });
      setLoader(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog custom-dialog-full"
    >
      <DialogTitle className="text-center">
        Add Participant <br /> <small> {event?.eventName}</small>
      </DialogTitle>
      <DialogContent>
        {loader && <FullPageProgress />}

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <TagsInput
              addKeys={[188, 9, 13, 32]}
              value={emails}
              onChange={(e) => {
                addEmails(e);
              }}
              inputProps={{ placeholder: "Enter email and press Enter" }}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <div className="event-participant-sample">
              Send bulk invites
              <span
                className="event-participant-sample-link"
                onClick={donwloadSampleInvite}
              >
                Download Sample CSV
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <FormControl>
              <label className="custom-form-file mt-10">
                <input
                  type="file"
                  id="participant-file"
                  accept=".csv"
                  className="custom-form-file-input"
                  onChange={onSelectCSV}
                />
                <CloudUploadIcon />{" "}
                <span className="promote_eventpopup-file-name">
                  {inviteCSV?.name || " Attach CSV"}
                </span>
              </label>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <button
          type="button"
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-primary"
          onClick={addNewStaff}
        >
          Submit
        </button>
        <button
          onClick={handleClose}
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-secondary"
        >
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  );
}
