import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import {
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from "@mui/material";
import { SESSION_AUTH } from "../../../helpers/auth";
import { ANALYTICS_SERVICES } from "../../../services";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";

export default function EventDistribution() {
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [loader, setLoader] = useState(true);
  const [filterDate, setFilterDate] = useState("Last 7 days");
  const [chartOptions, setChartOptions] = useState("");

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [userSession]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoader(true);
    let start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 999);

    let startDate = new Date(start);
    let endDate = new Date(end);
    if (filterDate === "Today") {
    } else if (filterDate === "Yesterday") {
      startDate.setDate(startDate.getDate() - 1);
      endDate.setDate(endDate.getDate() - 1);
    } else if (filterDate === "Last 7 days") {
      startDate.setDate(startDate.getDate() - 7);
    } else if (filterDate === "Last 14 days") {
      startDate.setDate(startDate.getDate() - 14);
    } else if (filterDate === "Last 30 days") {
      startDate.setDate(startDate.getDate() - 30);
    } else if (filterDate === "Last 90 days") {
      startDate.setDate(startDate.getDate() - 90);
    } else if (filterDate === "Last 180 days") {
      startDate.setDate(startDate.getDate() - 180);
    } else if (filterDate === "Last one year") {
      startDate.setFullYear(startDate.getFullYear() - 1);
    }
    ANALYTICS_SERVICES.eventDistribution({
      userId: userData.userId,
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    })
      .then((data) => {
        if (data.code === 200) {
          let options = {
            chart: {
              plotBackgroundColor: "transparent",
              backgroundColor: "transparent",
              plotBorderWidth: 0,
              plotShadow: false,
              type: "pie",
            },
            colors: ["#F5EACA", "#DBB163", "#A78F77", "#60574D", "#575757"],
            title: {
              text: filterDate,
              align: "center",
              verticalAlign: "bottom",
              style: {
                color: "white",
              },
            },
            tooltip: {
              headerFormat: "",
              pointFormat: "<b>{point.name}:{point.y}</b>",
            },

            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                showInLegend: true,
                dataLabels: {
                  format: "<b>{point.name}</b>: {point.percentage:.2f} %",
                  color: "white",
                  textShadow: "none",
                },
              },
            },
            legend: {
              itemStyle: {
                color: "white",
                fontWeight: "bold",
              },
              itemHoverStyle: {
                color: "#e3c050",
              },
            },
            series: [
              {
                data: [
                  {
                    name: "Public Event",
                    y: data?.data?.public,
                  },
                  {
                    name: "Private Event",
                    y: data?.data?.private,
                  },
                ],
              },
            ],
          };
          setChartOptions(options);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, filterDate]);

  if (loader) {
    return <FullPageProgress />;
  }
  return (
    <Box className="managepro6ix-layout-page-content">
      <div className="managepro6ix-layout-page-content-header">
        <div className="managepro6ix-layout-page-content-header-left">
          <div className="managepro6ix-layout-page-content-header-heading">
            Distribution of Events
          </div>
        </div>
        <div className="managepro6ix-layout-page-content-header-action">
          <FormControl fullWidth>
            <Select
              value={filterDate}
              onChange={(e) => {
                setFilterDate(e.target.value);
                setDataFetched(false);
              }}
            >
              <MenuItem value="Today">Today</MenuItem>
              <MenuItem value="Yesterday">Yesterday</MenuItem>
              <MenuItem value="Last 7 days">Last 7 days</MenuItem>
              <MenuItem value="Last 14 days">Last 14 days</MenuItem>
              <MenuItem value="Last 30 days">Last 30 days</MenuItem>
              <MenuItem value="Last 90 days">Last 90 days</MenuItem>
              <MenuItem value="Last 180 days">Last 180 days</MenuItem>
              <MenuItem value="Last one year">Last one year</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <Grid
        container
        spacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ marginTop: "10px" }}
      >
        <Grid item xs={12} sm={12} md={12}>
          {chartOptions && (
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="caption">
            This pie chart shows the percentage distribution of events created
            on the platform, categorized as Private and Public Events. It gives
            a quick overview of the types of events being hosted on the
            platform.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
