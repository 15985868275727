import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import HeaderAnalytics from "./HeaderAnalytics";
import HeaderEvents from "./HeaderEvents";
import HeaderAi from "./HeaderAi";
import HeaderManage from "./HeaderManage";
import { SESSION_AUTH } from "../../../helpers/auth";
import { PERMISSION_LIST } from "../../../helpers/constants";

const HeaderBottom = () => {
  const { userSession } = SESSION_AUTH();
  const [modulePermissions, setModulePermissions] = useState();

  useEffect(() => {
    if (userSession) {
      setModulePermissions(userSession?.permission);
    } else {
      setModulePermissions("");
    }
    return () => null;
  }, [userSession]);
  return (
    <div className="managepro6ix-layout-header-bottom">
      <div className="managepro6ix-layout-header-bottom-menu">
        <NavLink to="/" className="managepro6ix-layout-header-bottom-menu-link">
          Dashboard
        </NavLink>

        <HeaderAnalytics />
        {modulePermissions?.[PERMISSION_LIST?.events?.value]?.view && (
          <HeaderEvents />
        )}
        {modulePermissions?.[PERMISSION_LIST?.ai?.value]?.view && <HeaderAi />}
        {modulePermissions?.[PERMISSION_LIST?.manage.value]?.view && (
          <HeaderManage />
        )}
      </div>
    </div>
  );
};

export default HeaderBottom;
