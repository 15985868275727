import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import DataTable from "../../Table/DataTable";
import AddIcon from "@mui/icons-material/Add";
import AddNewStaff from "./AddNewStaff";
import EditStaff from "./EditStaff";

import { ConfirmModal } from "../../Modals/ConfirmModal";
import { STAFF_SERVICES } from "../../../services";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";
import Moment from "moment";
import { USER_STATUS_LIST } from "../../../helpers/constants";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";

export default function ManageStaff(props) {
  const { module } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [messageConfirm, setMessageConfirm] = useState();
  const [titleConfirm, setTitleConfirm] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState();
  const [confirmRequestData, setConfirmRequestData] = useState();
  const [open, setOpen] = useState(false);
  const [openEditStaff, setEditStaff] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();
  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  const confirmRequest = (title, message) => {
    setOpenConfirm(true);
    setTitleConfirm(title);
    setMessageConfirm(message);
  };
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    { name: "sno", label: "S.No." },
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "phoneNumber", label: "Phone" },
    { name: "role", label: "Role" },
    { name: "createdAt", label: "Created On" },
    { name: "lastupdatedAt", label: "Last Updated" },
    { name: "lastupdatedBy", label: "Updated By" },
    { name: "statusName", label: "Status" },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        display: modulePermissions?.edit || modulePermissions?.delete,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return userData.userId !== staffList?.[dataIndex]?.id ? (
            <span className="">
              {modulePermissions?.edit && (
                <button
                  className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-medium"
                  onClick={() => editStaff(dataIndex)}
                >
                  Edit
                </button>
              )}
              {modulePermissions?.delete && (
                <button
                  className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-medium m-l-10"
                  onClick={() => {
                    userConfirmDelete(dataIndex);
                  }}
                >
                  Delete
                </button>
              )}
              {modulePermissions?.edit && (
                <>
                  {staffList?.[dataIndex].status ===
                  USER_STATUS_LIST?.active?.value ? (
                    <button
                      className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-medium m-l-10"
                      onClick={() => {
                        userConfirmStatus(
                          dataIndex,
                          USER_STATUS_LIST?.suspend?.value
                        );
                      }}
                    >
                      {USER_STATUS_LIST?.suspend?.name}
                    </button>
                  ) : staffList?.[dataIndex].status ===
                    USER_STATUS_LIST.suspend.value ? (
                    <button
                      className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-medium m-l-10"
                      onClick={() => {
                        userConfirmStatus(
                          dataIndex,
                          USER_STATUS_LIST?.active?.value
                        );
                      }}
                    >
                      {USER_STATUS_LIST.active.name}
                    </button>
                  ) : null}
                </>
              )}
            </span>
          ) : null;
        },
      },
    },
  ];

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions?.view) return;

    setLoader(true);
    setDataFetched(true);
    setStaffList([]);
    STAFF_SERVICES.getStaff({ userId: userData.userId })
      .then((data) => {
        if (data.code === 200) {
          setStaffList(data?.data || []);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, modulePermissions]);

  const getStafList = async () => {
    setDataFetched(false);
  };

  const userConfirmDelete = async (dataIndex) => {
    let item = staffList?.[dataIndex];
    if (item?.id) {
      setSelectedStaff(item);
      confirmRequest(item?.name, " Are You sure for delete?");
      setConfirmRequestData({ action: "delete" });
    }
  };
  const userConfirmStatus = async (dataIndex, type) => {
    let item = staffList?.[dataIndex];
    if (item?.id) {
      setSelectedStaff(item);
      setConfirmRequestData({
        action: "status",
        status: USER_STATUS_LIST?.[type]?.value,
      });
      confirmRequest(
        item?.name,
        ` Are You sure for ${USER_STATUS_LIST?.[type]?.name}?`
      );
    }
  };

  const confirmRequestCancel = () => {
    setOpenConfirm(false);
    setSelectedStaff("");
    setConfirmRequestData("");
  };
  const confirmRequestAccept = async () => {
    setOpenConfirm(false);
    if (selectedStaff?.id) {
      if (
        confirmRequestData?.action === "delete" &&
        modulePermissions?.delete
      ) {
        try {
          setLoader(true);
          let parma = { id: selectedStaff?.id, userId: userSession?.userId };
          const data = await STAFF_SERVICES.deleteStaff(parma);
          if (data.code === 200) {
            ToastManager.addToast(data.message);
            getStafList();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
          }
        } catch (error) {
          ToastManager.addToast(error.message);
        }
        setLoader(false);
      } else if (
        confirmRequestData?.action === "status" &&
        confirmRequestData?.status &&
        modulePermissions?.edit
      ) {
        setLoader(true);
        try {
          let parma = {
            id: selectedStaff?.id,
            status: confirmRequestData?.status,
            userId: userSession?.userId,
          };

          const data = await STAFF_SERVICES.changeStatus(parma);
          if (data.code === 200) {
            ToastManager.addToast(data.message);
            getStafList();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
          }
        } catch (error) {
          ToastManager.addToast(error.message);
        }
        setLoader(false);
      }
    }
  };

  const editStaff = async (dataIndex) => {
    let item = staffList?.[dataIndex];
    if (item?.id) {
      setSelectedStaff(item);
      setEditStaff(true);
    }
  };
  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  return (
    <>
      {loader && <FullPageProgress />}
      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              Manage Staff
            </div>
          </div>
          {modulePermissions?.add && (
            <div className="managepro6ix-layout-page-content-header-action">
              <button
                className="managepro6ix-btn managepro6ix-btn-primary"
                onClick={() => setOpen(true)}
              >
                <AddIcon className="m-r-5" /> Add new
              </button>
            </div>
          )}
        </div>

        <div className="managepro6ix-table-center">
          <DataTable
            columns={columns}
            data={staffList.map((item, key) => {
              return {
                sno: key + 1,
                name: item.name,
                email: item.email,
                phoneNumber: item.phoneNumber,
                role: item?.role,
                createdAt: Moment(item?.createdAt).format(
                  "MMM DD, YYYY : hh:mm A"
                ),
                lastupdatedAt: Moment(item?.updatedBy?.date).format(
                  "MMM DD, YYYY : hh:mm A"
                ),
                lastupdatedBy: item?.updatedBy?.name,
                statusName: USER_STATUS_LIST?.[item?.status]?.name,
                id: item?.id,
              };
            })}
          />
        </div>
        {open && modulePermissions?.add && (
          <AddNewStaff
            module={module}
            open={open}
            update={getStafList}
            setOpen={setOpen}
          />
        )}
        {openEditStaff && selectedStaff && modulePermissions?.edit && (
          <EditStaff
            update={getStafList}
            open={openEditStaff}
            setOpen={setEditStaff}
            staff={selectedStaff}
          />
        )}
        {openConfirm && (
          <ConfirmModal
            title={titleConfirm}
            message={messageConfirm}
            request={openConfirm}
            cancel={confirmRequestCancel}
            accept={confirmRequestAccept}
          />
        )}
      </Box>
    </>
  );
}
