import React from "react";
import { Box } from "@mui/material";
import Login from "../components/Account/Login/Login";

const LoginPage = (props) => {
  return (
    <React.Fragment>
      <Box className="managepro6ix-layout managepro6ix-layout-full">
        <Box className="managepro6ix-layout-page">
          <Login />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LoginPage;
