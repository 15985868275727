import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { List, ListItemButton, ListItemText, Menu } from "@mui/material";
import { AngleIcon } from "../../../Icons";

const HeaderManage = () => {
  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const headerMenuOpenReq = (event) => {
    setHeaderMenuEl(event.currentTarget);
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  return (
    <React.Fragment>
      <div
        className={`managepro6ix-layout-header-bottom-menu-link ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-manage-menu-button"
        aria-controls={headerMenuOpen ? "header-manage-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        Manage
        {headerMenuOpen ? <AngleIcon up /> : <AngleIcon small />}
      </div>

      <Menu
        id="header-manage-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-manage-menu-button",
        }}
        className="managepro6ix-layout-header-bottom-menu-link-dropdown managepro6ix-layout-header-bottom-menu-link-dropdown-users"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          component="nav"
          className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list"
        >
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/manage/staff"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Staff"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/manage/roles"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Roles"
            />
          </ListItemButton>
        </List>
      </Menu>
    </React.Fragment>
  );
};

export default HeaderManage;
