import { apiCall } from "./servicesCall";

export const EVENTS_SERVICES = {
  getUsers(data) {
    return apiCall("/events/users", data);
  },
  getUsersProfile(data) {
    return apiCall("/events/users/profile", data);
  },
  getUsersTotal(data) {
    return apiCall("/events/users/total", data);
  },
  loginMagicLink(data) {
    return apiCall("/events/users/login/link", data);
  },
  exportUserCSV(data) {
    return apiCall("/events/users/csv", data);
  },

  getEvents(data) {
    return apiCall("/events", data);
  },
  eventsCreateTicket(data) {
    return apiCall("/events/ticket/create", data);
  },
  deleteEvent(data) {
    return apiCall("/events/delete/event", data);
  },

  updateEventType(data) {
    return apiCall("/events/update/type", data);
  },
  getDashboard(data) {
    return apiCall("/events/dashboard", data);
  },
  addParticipant(data) {
    return apiCall("/events/participant/add", data);
  },
  exportEventCSV(data) {
    return apiCall("/events/csv", data);
  },
  getOrgazination(data) {
    return apiCall("/events/orgazination", data);
  },
  exportOrganizationCSV(data) {
    return apiCall("/events/orgazination/csv", data);
  },
  updateSubscription(data) {
    return apiCall("/events/orgazination/subscription/update", data);
  },
  getBillingSetup(data) {
    return apiCall("/events/orgazination/billing/setup", data);
  },
  updateBillingSetup(data) {
    return apiCall("/events/orgazination/billing/setup/update", data);
  },
  getTransitionsList(data) {
    return apiCall("/events/orgazination/transitions/list", data);
  },
  getTransitionsAll(data) {
    return apiCall("/events/orgazination/transitions/all", data);
  },
  getPlans(data) {
    return apiCall("/events/orgazination/plans", data);
  },
};
