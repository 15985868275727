import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { SESSION_AUTH } from "../../../helpers/auth";
import { EVENTS_SERVICES } from "../../../services";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";

export function EventOrganizationCSV(props) {
  const { filter } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [userSession, navigate]);

  const exportToCsv = () => {
    setLoader(true);
    try {
      let parma = { ...filter };
      parma["userId"] = userData.userId;
      EVENTS_SERVICES.exportOrganizationCSV(parma)
        .then((data) => {
          if (data.code === 200) {
            ToastManager.addToast(data.message);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data.message);
          }
        })
        .catch((error) => {
          ToastManager.addToast(error.message);
        })
        .finally(() => {
          setLoader(false);
        });
    } catch (error) {
      ToastManager.addToast(error.message);
    }
  };
  return (
    <>
      {loader && <FullPageProgress />}
      <button
        type="button"
        onClick={() => {
          exportToCsv();
        }}
        className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
      >
        <FileDownloadIcon className="m-r-5" /> Export CSV
      </button>
    </>
  );
}
