import React, { useEffect, useState } from "react";
import {
  List,
  ListItemButton,
  Typography,
  ListItemText,
  Menu,
} from "@mui/material";
import { ExitIcon, UserIcon } from "../../Icons";
import { SESSION_AUTH } from "../../helpers/auth";

const HeaderUsers = () => {
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState(null);

  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const headerMenuOpenReq = (event) => {
    setHeaderMenuEl(event.currentTarget);
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [userSession]);

  const handleLogout = () => {
    LOGOUT_USER_SESSION(userSession);
    headerMenuClose();
  };
  if (!userSession) {
    return;
  }
  return (
    <>
      <div
        className={`managepro6ix-layout-header-right-item-btn ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-users-menu-button"
        aria-controls={headerMenuOpen ? "header-users-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        <div className="managepro6ix-layout-header-right-item-btn-user">
          {userData?.profileImage ? (
            <img src={userData.profileImage} alt="6ix user" />
          ) : (
            <UserIcon />
          )}
        </div>
      </div>

      <Menu
        id="header-users-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-users-menu-button",
        }}
        className="managepro6ix-layout-header-right-item-dropdown managepro6ix-layout-header-right-item-dropdown-users"
      >
        <List
          component="nav"
          className="managepro6ix-layout-header-right-item-dropdown-users-list"
        >
          <ListItemButton
            className="managepro6ix-layout-header-right-item-dropdown-users-list-item"
            component="div"
            onClick={() => {
              headerMenuClose();
            }}
          >
            <div>
              <Typography textAlign="left" fontSize={12} color="GrayText">
                Signed in as
              </Typography>

              <ListItemText
                className="managepro6ix-layout-header-right-item-dropdown-users-list-item-text"
                primary={userData?.name}
              />
            </div>
          </ListItemButton>

          <div className="managepro6ix-layout-header-right-item-dropdown-users-list-divider"></div>
          <ListItemButton
            className="managepro6ix-layout-header-right-item-dropdown-users-list-item"
            component="a"
            onClick={handleLogout}
          >
            <ExitIcon />
            <ListItemText
              className="managepro6ix-layout-header-right-item-dropdown-users-list-item-text"
              primary="Log Out"
            />
          </ListItemButton>
        </List>
      </Menu>
    </>
  );
};

export default HeaderUsers;
