import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Grid,
  Box,
  TablePagination,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

import DataTable from "../../Table/DataTable";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import Moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import { EVENTS_SERVICES } from "../../../services";
import FullPageProgress from "../../common/FullPageProgress";
import ShowProfileModel from "../../Modals/ShowProfileModel";
import CreateSubscriptionModal from "./CreateSubscriptionModal";
import SubscriptionBillingModal from "./SubscriptionBillingModal";
import {
  SUBSCRIPTION_STATUS_LIST,
  SUBSCRIPTION_MANAGE,
} from "../../../helpers/constants";
import { EventOrganizationCSV } from "./EventOrganizationCSV";

export default function EventOrgazination(props) {
  const { module } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [planFetched, setPlanFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();
  const [orgList, setOrgList] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [subscriptionOpen, setSubscriptionOpen] = useState(false);
  const [billingSetupOpen, setBillingSetupOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [SUBSCRIPTION_PLAN, setSUBSCRIPTION_PLAN] = useState("");

  const defaultFilter = {
    page: 0,
    limit: 10,
    orgName: "",
    subscriptionType: "",
    subscriptionManage: "",
    subscriptionStatus: "",
    subscriptionPlan: "",
    subscriptionStartDateFrom: null,
    subscriptionStartDateTo: null,
    subscriptionEndDateFrom: null,
    subscriptionEndDateTo: null,
    orgOwner: "",
  };
  const [filter, setFilter] = useState(defaultFilter);
  const [profileData, setProfileData] = useState();
  const [open, setOpen] = useState(false);

  const handleOpen = (value) => {
    setOpen(true);
    setProfileData(value);
  };

  const getCurrentPlan = (product) => {
    let plan;
    if (product) {
      for (const [key] of Object.entries(SUBSCRIPTION_PLAN)) {
        if (SUBSCRIPTION_PLAN[key].productId === product) {
          plan = SUBSCRIPTION_PLAN[key];
        }
      }
    }
    return plan;
  };

  const handleChangePage = (event, newPage) => {
    setFilter((old) => ({
      ...old,
      page: newPage,
    }));
    setDataFetched(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter((old) => ({
      ...old,
      limit: event.target.value,
      page: 0,
    }));
    setDataFetched(false);
  };

  const onChangeFilter = (name, value) => {
    let f = { ...filter };
    f[name] = value;
    setFilter(f);
  };
  const applyFilter = () => {
    setFilter((old) => ({
      ...old,
      page: 0,
    }));
    setDataFetched(false);
  };
  const clearFilter = () => {
    setFilter(defaultFilter);
    setDataFetched(false);
  };

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (!userData || planFetched || !modulePermissions?.view) return;
    setLoader(true);
    setPlanFetched(true);
    let parma = { userId: userData.userId };
    EVENTS_SERVICES.getPlans(parma)
      .then((data) => {
        if (data.code === 200) {
          setSUBSCRIPTION_PLAN(data?.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, planFetched, LOGOUT_USER_SESSION, modulePermissions]);

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions?.view) return;

    setLoader(true);
    setDataFetched(true);

    let parma = { ...filter };
    parma["userId"] = userData.userId;
    EVENTS_SERVICES.getOrgazination(parma)
      .then((data) => {
        setOrgList([]);
        if (data.code === 200) {
          setOrgList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, modulePermissions, filter]);

  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    { name: "sno", label: "S.No." },
    {
      name: "orgLogo",
      label: "Logo",
      options: {
        customBodyRender: (item) => {
          return item?.logo ? (
            <Avatar
              style={{ background: item?.color }}
              src={item?.logo}
              alt=""
            />
          ) : null;
        },
      },
    },
    {
      name: "orgName",
      label: "Name",
      options: {
        customBodyRender: (item) => {
          return (
            <Link
              to={`${process.env.REACT_APP_EVENT_PLATFORM}/company/${item?.slug}`}
              target="_blank"
              className="text-link"
            >
              {item?.name}
            </Link>
          );
        },
      },
    },
    {
      name: "owner",
      label: "Owner",
      options: {
        customBodyRender: (value) => {
          return (
            <span
              className="text-link"
              onClick={() => {
                handleOpen(value);
              }}
            >
              {value?.name}
            </span>
          );
        },
      },
    },
    { name: "createdAt", label: "Created" },
    { name: "teams", label: "Teams" },
    { name: "events", label: "Events" },
    {
      name: "subscriptionStatus",
      label: "Subscription",
      options: {
        customBodyRender: (value) => {
          switch (value) {
            case 1:
              return SUBSCRIPTION_STATUS_LIST?.[1]?.name;
            case 2:
              return SUBSCRIPTION_STATUS_LIST?.[2]?.name;
            case 3:
              return SUBSCRIPTION_STATUS_LIST?.[3]?.name;
            case 4:
              return SUBSCRIPTION_STATUS_LIST?.[4]?.name;
            case 5:
              return SUBSCRIPTION_STATUS_LIST?.[5]?.name;
            case 6:
              return SUBSCRIPTION_STATUS_LIST?.[6]?.name;
            default:
              return "-";
          }
        },
      },
    },
    {
      name: "subscriptionPlan",
      label: "Plan",
      options: {
        customBodyRender: (plan) => {
          return getCurrentPlan(plan?.product)?.name || "";
        },
      },
    },
    {
      name: "subscriptionManage",
      label: "Manage",
      options: {
        customBodyRender: (item) => {
          return (
            <div>
              {item?.manage} - {item?.type}
            </div>
          );
        },
      },
    },
    { name: "subscriptioStartDate", label: "Subscription Start" },
    { name: "subscriptioEndDate", label: "Subscription End" },

    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (item) => {
          return (
            <>
              {modulePermissions?.add ? (
                <>
                  {item?.subscription?.status ? (
                    <button
                      onClick={() => {
                        createSubscription(item);
                      }}
                      type="button"
                      className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary m-l-5"
                    >
                      Update Subscription
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        createSubscription(item);
                      }}
                      type="button"
                      className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary m-l-5"
                    >
                      Create Subscription
                    </button>
                  )}
                  {item?.subscription?.customer ? (
                    <>
                      <button
                        onClick={() => {
                          billingSetup(item);
                        }}
                        type="button"
                        className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary m-l-5"
                      >
                        Update Billing Setup
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        billingSetup(item);
                      }}
                      type="button"
                      className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary m-l-5"
                    >
                      Create Billing Setup
                    </button>
                  )}
                </>
              ) : null}
              {item?.subscription?.customer ? (
                <>
                  <Link
                    to={`/events/organization/${item._id}/transactions`}
                    className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary m-l-5"
                  >
                    View Transaction
                  </Link>
                </>
              ) : null}
            </>
          );
        },
      },
    },
  ];
  const customOption = {
    pagination: false,
    sort: false,
  };

  const createSubscription = (item) => {
    setSelectedAccount(item);
    setSubscriptionOpen(true);
  };
  const billingSetup = (item) => {
    setSelectedAccount(item);
    setBillingSetupOpen(true);
  };
  const convetFDate = (d, type) => {
    let date = new Date(d);
    if (type === "from") {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(23, 59, 59, 999);
    }
    return date;
  };
  return (
    <>
      {loader && <FullPageProgress />}
      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              6ix Organizations
            </div>
          </div>
          <div className="managepro6ix-layout-page-content-header-action">
            {orgList?.length > 0 ? (
              <EventOrganizationCSV filter={filter} />
            ) : null}
          </div>
        </div>
        <Accordion>
          <AccordionSummary
            sx={{ marginTop: 2, width: "100px" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ textTransform: "uppercase", fontSize: "14px" }}>
              Filters
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={{ xs: 1, md: 2 }}>
              {/* First Row */}
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Organization Name"
                  value={filter?.orgName}
                  onChange={(e) => onChangeFilter("orgName", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Owner Email"
                  value={filter?.orgOwner}
                  onChange={(e) => onChangeFilter("orgOwner", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-manage">
                    Subscription Manage:
                  </InputLabel>
                  <Select
                    label="Select Plan"
                    name="plans"
                    value={filter?.subscriptionManage || ""}
                    onChange={(e) =>
                      onChangeFilter("subscriptionManage", e.target.value)
                    }
                  >
                    {Object.keys(SUBSCRIPTION_MANAGE).map((item, index) => (
                      <MenuItem
                        key={index}
                        value={SUBSCRIPTION_MANAGE[item].value}
                      >
                        {SUBSCRIPTION_MANAGE[item].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-type">
                    Subscription Type:
                  </InputLabel>
                  <Select
                    label="Select Plan"
                    name="plans"
                    value={filter?.subscriptionType || ""}
                    onChange={(e) =>
                      onChangeFilter("subscriptionType", e.target.value)
                    }
                  >
                    <MenuItem value="free">Free</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-status">
                    Subscription Status:
                  </InputLabel>
                  <Select
                    label="Subscription Status"
                    name="plans"
                    value={filter?.subscriptionStatus || ""}
                    onChange={(e) =>
                      onChangeFilter("subscriptionStatus", e.target.value)
                    }
                  >
                    {Object.keys(SUBSCRIPTION_STATUS_LIST).map(
                      (item, index) => (
                        <MenuItem
                          key={index}
                          value={SUBSCRIPTION_STATUS_LIST[item].value}
                        >
                          {SUBSCRIPTION_STATUS_LIST[item].name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-label-plan">
                    Subscription Plan:
                  </InputLabel>
                  <Select
                    label="Subscription Plan"
                    name="plans"
                    value={filter?.subscriptionPlan || ""}
                    onChange={(e) =>
                      onChangeFilter("subscriptionPlan", e.target.value)
                    }
                  >
                    {Object.keys(SUBSCRIPTION_PLAN).map((item, index) => (
                      <MenuItem
                        key={index}
                        value={SUBSCRIPTION_PLAN[item]?.priceId}
                      >
                        {SUBSCRIPTION_PLAN[item]?.name} - ( $
                        {SUBSCRIPTION_PLAN[item]?.price}) - per investor profile
                        ($
                        {SUBSCRIPTION_PLAN[item]?.unit?.value || 0})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Subscription Start From"
                    format="MMM DD YYYY"
                    value={
                      filter?.subscriptionStartDateFrom
                        ? dayjs(filter?.subscriptionStartDateFrom)
                        : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "subscriptionStartDateFrom",
                        newValue?.$d ? convetFDate(newValue?.$d, "from") : null
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Subscription Start To"
                    format="MMM DD YYYY"
                    value={
                      filter?.subscriptionStartDateTo
                        ? dayjs(filter?.subscriptionStartDateTo)
                        : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "subscriptionStartDateTo",
                        newValue?.$d ? convetFDate(newValue?.$d, "to") : null
                        //newValue?.$d ? newValue?.$d : null
                      );
                    }}
                    minDate={dayjs(filter?.subscriptionStartDateFrom)}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Subscription End From"
                    format="MMM DD YYYY"
                    value={
                      filter?.subscriptionEndDateFrom
                        ? dayjs(filter?.subscriptionEndDateFrom)
                        : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "subscriptionEndDateFrom",
                        newValue?.$d ? convetFDate(newValue?.$d, "from") : null
                      );
                    }}
                    minDate={dayjs(filter?.subscriptionStartDateTo)}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Subscription End To"
                    format="MMM DD YYYY"
                    value={
                      filter?.subscriptionEndDateTo
                        ? dayjs(filter?.subscriptionEndDateTo)
                        : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "subscriptionEndDateTo",
                        newValue?.$d ? convetFDate(newValue?.$d, "to") : null
                      );
                    }}
                    minDate={dayjs(filter?.subscriptionEndDateFrom)}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item sx={{ marginTop: 1 }}>
                <button
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-primary"
                  onClick={applyFilter}
                >
                  Search
                </button>
              </Grid>
              <Grid item sx={{ marginTop: 1 }}>
                <button
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-primary"
                  onClick={clearFilter}
                >
                  Clear
                </button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <div className="managepro6ix-table-center">
          <DataTable
            customOption={customOption}
            columns={columns}
            data={orgList.map((item, key) => {
              return {
                action: item,
                sno: filter?.page * filter?.limit + key + 1,
                orgName: { name: item.orgName, slug: item.orgSlug },
                orgLogo: { logo: item?.orgLogo, color: item?.orgLogoColor },
                owner: item.owner,
                teams: item.teams,
                events: item.events,
                createdAt: Moment(item?.createdAt).format(
                  "MMM DD, YYYY : hh:mm A"
                ),
                id: item?._id,
                subscriptionStatus: item?.subscription?.status
                  ? item?.subscription?.status
                  : item?.subscription?.statusLabel
                  ? item?.subscription?.statusLabel
                  : 0,
                subscriptionPlan: item?.subscription?.plan,
                subscriptionManage: {
                  manage:
                    SUBSCRIPTION_MANAGE?.[item?.subscription?.manage]?.name,
                  type: item?.subscription?.type,
                },
                subscriptioStartDate: item?.subscription?.startDate
                  ? Moment(item?.subscription?.startDate).format(
                      "MMM DD, YYYY : hh:mm A"
                    )
                  : "",
                subscriptioEndDate: item?.subscription?.endDate
                  ? Moment(item?.subscription?.endDate).format(
                      "MMM DD, YYYY : hh:mm A"
                    )
                  : "",
              };
            })}
          />
        </div>
        <TablePagination
          component="div"
          count={totalRows}
          page={filter.page}
          onPageChange={handleChangePage}
          rowsPerPage={filter.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </Box>
      {open && (
        <ShowProfileModel open={open} setOpen={setOpen} data={profileData} />
      )}
      {subscriptionOpen && selectedAccount && modulePermissions?.add && (
        <CreateSubscriptionModal
          SUBSCRIPTION_PLAN={SUBSCRIPTION_PLAN}
          module={module}
          open={subscriptionOpen}
          orgAccount={selectedAccount}
          onClose={() => setSubscriptionOpen(false)}
          onSave={() => {
            setDataFetched(false);
            setSubscriptionOpen(false);
          }}
        />
      )}
      {billingSetupOpen && selectedAccount && modulePermissions?.add && (
        <SubscriptionBillingModal
          module={module}
          open={billingSetupOpen}
          orgAccount={selectedAccount}
          onClose={() => setBillingSetupOpen(false)}
          onSave={() => {
            setDataFetched(false);
            setBillingSetupOpen(false);
          }}
        />
      )}
    </>
  );
}
