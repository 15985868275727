import React from "react";

export function GoogleIcon(props) {
  return props?.type === "white" ? (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.50365 9.01813L3.50396 9.00348L3.5034 8.98884C3.39411 6.11145 5.93766 3.42925 8.81594 3.39478L8.83423 3.39456L8.85245 3.393C10.1345 3.28366 11.4065 3.70943 12.4708 4.46592C12.1787 4.78061 11.8839 5.08814 11.5806 5.38433C10.5284 4.8031 9.21097 4.36053 7.8836 4.82101C5.59727 5.48168 4.22277 8.15339 5.06175 10.4019C5.77623 12.7452 8.59256 14.011 10.8254 13.0391C12.033 12.599 12.7854 11.502 13.1081 10.3963L13.291 9.76937L12.638 9.75636C12.0942 9.74552 11.5701 9.74277 11.0501 9.74005C10.5732 9.73754 10.0998 9.73506 9.61798 9.72638C9.61661 9.36034 9.61562 8.99524 9.61587 8.63C9.66624 8.62991 9.71661 8.62983 9.76699 8.62975C11.3441 8.62713 12.9204 8.62452 14.4965 8.63572C14.5364 10.1299 14.2454 11.6094 13.3445 12.7217L13.3444 12.7216L13.3382 12.7296C11.9753 14.4836 9.41 15.0342 7.29083 14.3223L7.28763 14.3212C5.07233 13.5936 3.45559 11.3495 3.50365 9.01813Z"
        fill="white"
        stroke="#C0C0C0"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8 10.7083C18.8 10.0583 18.7417 9.43325 18.6333 8.83325H10V12.3791H14.9333C14.7208 13.5249 14.075 14.4958 13.1042 15.1458V17.4458H16.0667C17.8 15.8499 18.8 13.4999 18.8 10.7083Z"
        fill="#4285F4"
      />
      <path
        d="M10.0003 19.6667C12.4753 19.6667 14.5503 18.8459 16.0669 17.4459L13.1044 15.1459C12.2836 15.6959 11.2336 16.0209 10.0003 16.0209C7.61276 16.0209 5.59193 14.4084 4.87109 12.2417H1.80859V14.6167C3.31693 17.6125 6.41693 19.6667 10.0003 19.6667Z"
        fill="#34A853"
      />
      <path
        d="M4.87051 12.2416C4.68717 11.6916 4.58301 11.1041 4.58301 10.5C4.58301 9.8958 4.68717 9.3083 4.87051 8.7583V6.3833H1.80801C1.18717 7.6208 0.833008 9.0208 0.833008 10.5C0.833008 11.9791 1.18717 13.3791 1.80801 14.6166L4.87051 12.2416Z"
        fill="#FBBC05"
      />
      <path
        d="M10.0003 4.97909C11.3461 4.97909 12.5544 5.44159 13.5044 6.34992L16.1336 3.72075C14.5461 2.24159 12.4711 1.33325 10.0003 1.33325C6.41693 1.33325 3.31693 3.38742 1.80859 6.38325L4.87109 8.75825C5.59193 6.59159 7.61276 4.97909 10.0003 4.97909Z"
        fill="#EA4335"
      />
    </svg>
  );
}
export default GoogleIcon();
