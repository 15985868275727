import React, { useEffect, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  FormControl,
  TextField,
  Grid,
  MenuItem,
  Select,
  Box,
  TablePagination,
  Button,
} from "@mui/material";
import DataTable from "../../Table/DataTable";

import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import ShowProfileModel from "../../Modals/ShowProfileModel";
import ShowSpeakersModal from "../../Modals/ShowSpeakersModal";
import { EventListCSV } from "./EventListCSV";

import "react-datepicker/dist/react-datepicker.css";

import Moment from "moment";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import { EVENTS_SERVICES } from "../../../services";
import FullPageProgress from "../../common/FullPageProgress";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import AddNewParticipant from "../EventAttende/AddNewParticipant";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import EditEventTypeModal from "./EditEventTypeModal";
import { EVENT_TYPE_LIST, EVENT_STATUS_LIST } from "../../../helpers/constants";

export default function EventList(props) {
  const { module } = props;

  const [profileData, setProfileData] = useState();

  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();
  const [eventList, setEventList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSpeakerModal, setOpenSpeakerModal] = useState(false);
  const [speakerData, setSpeakerData] = useState("");
  const [deleteEvent, setDeleteEvent] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const defaultFilter = {
    page: 0,
    limit: 10,
    eventName: "",
    eventStatus: "",
    hostedByEmail: "",
    eventDateTo: null,
    eventDateFrom: null,
    createdAtTo: null,
    createdAtFrom: null,
    dialInPin: "",
    eventType: "",
  };
  const [filter, setFilter] = useState(defaultFilter);

  const [addParticipant, setAddParticipant] = useState(false);
  const [editType, setEditType] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions?.view) return;

    setLoader(true);
    setDataFetched(true);

    let parma = { ...filter };
    parma["userId"] = userData.userId;

    EVENTS_SERVICES.getEvents(parma)
      .then((data) => {
        setEventList([]);
        if (data.code === 200) {
          setEventList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, modulePermissions, filter]);

  const handleChangePage = (event, newPage) => {
    setFilter((old) => ({
      ...old,
      page: newPage,
    }));
    setDataFetched(false);
  };

  const handleOpen = (value) => {
    setOpen(true);
    setProfileData(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter((old) => ({
      ...old,
      limit: event.target.value,
      page: 0,
    }));
    setDataFetched(false);
  };

  const onChangeFilter = (name, value) => {
    let f = { ...filter };
    f[name] = value;
    setFilter(f);
  };
  const applyFilter = () => {
    setFilter((old) => ({
      ...old,
      page: 0,
    }));
    setDataFetched(false);
  };
  const clearFilter = () => {
    setFilter(defaultFilter);
    setDataFetched(false);
  };

  const createIremTicket = (eventId) => {
    setLoader(true);
    let parma = { userId: userData.userId, eventId: eventId };
    EVENTS_SERVICES.eventsCreateTicket(parma)
      .then((data) => {
        if (data.code === 200) {
          setDataFetched(false);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
        ToastManager.addToast(data?.message);
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const columns = [
    {
      name: "sno",
      label: "S.No.",
    },
    {
      name: "eventName",
      label: "Event Name",
    },
    {
      name: "owner",
      label: "Owner",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              className="text-link"
              onClick={() => {
                handleOpen(value);
              }}
            >
              {value?.name}
            </span>
          );
        },
      },
    },
    {
      name: "eventDate",
      label: "Event Date",
    },
    {
      name: "createdAt",
      label: "Created On",
    },

    {
      name: "eventStatus",
      label: "Current Status",
      options: {
        customBodyRender: (value) => {
          return <span className="text-status">{value}</span>;
        },
      },
    },
    {
      name: "eventType",
      label: "Type",
      options: {
        customBodyRender: (value) => {
          return (
            <Button
              size="medium"
              endIcon={<EditIcon />}
              type="button"
              onClick={() => {
                setSelectedEvent(value);
                setEditType(true);
              }}
            >
              {value?.eventType}
            </Button>
          );
        },
      },
    },
    {
      name: "dialinPin",
      label: "Dial-in Pin",
    },
    {
      name: "totalRegister",
      label: "Total Registrants",
    },
    {
      name: "totalAttends",
      label: "Total Attendes",
    },
    {
      name: "speakers",
      label: "Speakers",
      options: {
        customBodyRender: (speakers) => {
          return speakers?.length > 0 ? (
            <span
              className="text-link"
              onClick={() => {
                setOpenSpeakerModal(true);
                setSpeakerData(speakers);
              }}
            >
              {speakers?.length}
            </span>
          ) : (
            <>-</>
          );
        },
      },
    },
    {
      name: "interestedMeeting",
      label: "Meeting Request",
    },
    {
      name: "meetingComplete",
      label: "Meeting Complete",
    },

    {
      name: "pageviewsregistere",
      label: "Register Pageviews",
    },
    {
      name: "registrants",
      label: "Registrants",
    },
    {
      name: "pageviewsattendee",
      label: "Attendee Pageviews",
    },
    {
      name: "attendees",
      label: "Attendees",
    },
    {
      name: "pageviewsreplay",
      label: "Replay Pageviews",
    },
    {
      name: "getreplays",
      label: "Get the Replay",
    },
    {
      name: "pageviewsondemand",
      label: "On-Demand Pageviews",
    },
    {
      name: "ondemands",
      label: "On-Demand",
    },
    {
      name: "pageviewstotal",
      label: "Total Pageviews",
    },
    {
      name: "pageviewslast",
      label: "Last Pageviews",
    },

    {
      name: "irmTicket",
      label: "IRM",
      options: {
        customBodyRender: (value) => {
          if (value?.link) {
            return (
              <a
                href={value?.link}
                className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                View Ticket
              </a>
            );
          } else if (modulePermissions?.add) {
            return (
              <button
                onClick={() => createIremTicket(value?.eventId)}
                type="button"
                className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
              >
                Create Ticket
              </button>
            );
          } else {
            return <>-</>;
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (item) => {
          return (
            <>
              <a
                href={item?.eventMeetingLink}
                target="_blank"
                className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
                rel="noreferrer"
              >
                View
              </a>
              {modulePermissions?.add && (
                <button
                  onClick={() => {
                    setSelectedEvent(item);
                    setAddParticipant(true);
                  }}
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary m-l-5"
                >
                  Add Participant
                </button>
              )}
              {modulePermissions?.add && (
                <button
                  onClick={() => {
                    setSelectedEvent(item);
                    setDeleteEvent(true);
                  }}
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary m-l-5"
                >
                  Delete Event
                </button>
              )}
            </>
          );
        },
      },
    },
  ];
  const customOption = {
    pagination: false,
    sort: false,
  };

  const deleteRequestCancel = () => {
    setDeleteEvent(false);
    setSelectedEvent("");
  };

  const deleteRequestAccept = async () => {
    setDeleteEvent(false);
    if (selectedEvent?._id) {
      try {
        setLoader(true);
        let parma = { userId: userData.userId, eventId: selectedEvent?._id };
        EVENTS_SERVICES.deleteEvent(parma)
          .then((data) => {
            if (data.code === 200) {
              setDataFetched(false);
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            }
            ToastManager.addToast(data?.message);
          })
          .catch((error) => {
            ToastManager.addToast(error.message);
          })
          .finally(() => {
            setLoader(false);
          });
      } catch (error) {
        ToastManager.addToast(error.message);
      }
    }
  };

  const convetFDate = (d, type) => {
    let date = new Date(d);
    if (type === "from") {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(23, 59, 59, 999);
    }
    return date;
  };

  return (
    <>
      {loader && <FullPageProgress />}

      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              6ix Events
            </div>
          </div>

          <div className="managepro6ix-layout-page-content-header-action">
            {eventList?.length > 0 ? <EventListCSV filter={filter} /> : null}
          </div>
        </div>

        <Accordion>
          <AccordionSummary
            sx={{ marginTop: 2, width: "100px" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ textTransform: "uppercase", fontSize: "14px" }}>
              Filters
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginTop: 2 }}>
            <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  fullWidth
                  label="Event Name"
                  value={filter?.eventName}
                  onChange={(e) => onChangeFilter("eventName", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  type="email"
                  fullWidth
                  label="Created by email"
                  value={filter?.hostedByEmail}
                  onChange={(e) =>
                    onChangeFilter("hostedByEmail", e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Event Date From"
                    format="MMM DD YYYY"
                    value={
                      filter?.eventDateFrom
                        ? dayjs(filter?.eventDateFrom)
                        : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "eventDateFrom",
                        newValue?.$d ? convetFDate(newValue?.$d, "from") : null
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Event Date To"
                    format="MMM DD YYYY"
                    value={
                      filter?.eventDateTo ? dayjs(filter?.eventDateTo) : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "eventDateTo",
                        newValue?.$d ? convetFDate(newValue?.$d, "to") : null
                      );
                    }}
                    minDate={dayjs(filter?.eventDateFrom)}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <TextField
                  fullWidth
                  label="Dial-In-Pin"
                  value={filter?.dialInPin}
                  onChange={(e) => onChangeFilter("dialInPin", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="eventStatus">Event Status</InputLabel>
                  <Select
                    id="eventStatus"
                    value={filter?.eventStatus}
                    label="Event Status"
                    onChange={(e) =>
                      onChangeFilter("eventStatus", e.target.value)
                    }
                  >
                    {Object.keys(EVENT_STATUS_LIST).map((item, index) => (
                      <MenuItem
                        key={index}
                        value={EVENT_STATUS_LIST[item].value}
                      >
                        {EVENT_STATUS_LIST[item].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="eventType">Event Type</InputLabel>
                  <Select
                    id="eventType"
                    value={filter?.eventType}
                    label="Event Type"
                    onChange={(e) =>
                      onChangeFilter("eventType", e.target.value)
                    }
                  >
                    {Object.keys(EVENT_TYPE_LIST).map((item, index) => (
                      <MenuItem key={index} value={EVENT_TYPE_LIST[item].value}>
                        {EVENT_TYPE_LIST[item].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Created Date From"
                    format="MMM DD YYYY"
                    value={
                      filter?.createdAtFrom
                        ? dayjs(filter?.createdAtFrom)
                        : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "createdAtFrom",
                        newValue?.$d ? convetFDate(newValue?.$d, "from") : null
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    label="Created Date To"
                    format="MMM DD YYYY"
                    value={
                      filter?.createdAtTo ? dayjs(filter?.createdAtTo) : null
                    }
                    onChange={(newValue) => {
                      onChangeFilter(
                        "createdAtTo",
                        newValue?.$d ? convetFDate(newValue?.$d, "to") : null
                      );
                    }}
                    minDate={dayjs(filter?.createdAtFrom)}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item sx={{ marginTop: 1 }}>
                <button
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-primary"
                  onClick={applyFilter}
                >
                  Search
                </button>
              </Grid>
              <Grid item sx={{ marginTop: 1 }}>
                <button
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-primary"
                  onClick={clearFilter}
                >
                  Clear
                </button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <DataTable
          columns={columns}
          data={eventList.map((item, key) => {
            return {
              sno: filter?.page * filter?.limit + key + 1,
              eventName: item.eventName,
              eventType: item,
              owner: item.owner,
              eventDate: item?.eventDate
                ? Moment(item?.eventDate).format("MMM DD, YYYY : hh:mm A")
                : "TBD",
              createdAt: Moment(item?.createdAt).format(
                "MMM DD, YYYY : hh:mm A"
              ),

              eventStatus: item.eventStatus,
              action: item,
              totalAttends: item.totalAttends,
              totalRegister: item.totalRegister,
              speakers: item.speakers,
              interestedMeeting: item.interestedMeeting,
              meetingComplete: item.meetingComplete,
              irmTicket: { eventId: item?._id, link: item?.irmTicket?.link },
              dialinPin: item?.dialin?.pin || "-",
              id: item?._id,
              pageviewsregistere: item?.views?.register || "-",
              pageviewsattendee: item?.views?.attendee || "-",
              pageviewsreplay: item?.views?.replay || "-",
              pageviewsondemand: item?.views?.ondemand || "-",
              pageviewstotal: item?.views?.total || "-",
              pageviewslast: item?.views?.date
                ? Moment(item?.views?.date).format("MMM DD, YYYY : hh:mm A")
                : "-",
              registrants: item?.registrants,
              attendees: item?.attendees,
              getreplays: item?.getreplays,
              ondemands: item?.ondemands,
            };
          })}
          customOption={customOption}
        />

        <TablePagination
          component="div"
          count={totalRows}
          page={filter.page}
          onPageChange={handleChangePage}
          rowsPerPage={filter.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </Box>
      {addParticipant && selectedEvent && modulePermissions?.add && (
        <AddNewParticipant
          module={module}
          open={addParticipant}
          event={selectedEvent}
          setOpen={setAddParticipant}
        />
      )}
      {editType && selectedEvent && modulePermissions?.add && (
        <EditEventTypeModal
          module={module}
          open={editType}
          event={selectedEvent}
          setOpen={setEditType}
          onUpdate={() => {
            setDataFetched(false);
          }}
        />
      )}
      {open && (
        <ShowProfileModel open={open} setOpen={setOpen} data={profileData} />
      )}
      {openSpeakerModal && (
        <ShowSpeakersModal
          open={openSpeakerModal}
          setOpen={setOpenSpeakerModal}
          data={speakerData}
        />
      )}
      {selectedEvent && deleteEvent && (
        <ConfirmModal
          title={selectedEvent?.eventName}
          message={`<p>${selectedEvent?.owner?.name}</p> <p> ${
            selectedEvent?.eventDate
              ? Moment(selectedEvent?.eventDate).format(
                  "MMM DD, YYYY : hh:mm A"
                )
              : "TBD"
          } </p>  Are you sure for delete `}
          request={deleteEvent}
          cancel={deleteRequestCancel}
          accept={deleteRequestAccept}
        />
      )}
    </>
  );
}
