import React from "react";
import { Navigate } from "react-router-dom";
import { SESSION_AUTH } from "./auth";

const AccessControl = ({ children }) => {
  const { userSession } = SESSION_AUTH();

  const isLogin = () => {
    return userSession ? true : false;
  };
  const isAllowAcceess = () => {
    const module = children?.props?.module;
    if (module === "dashboard") {
      return true;
    } else {
      return userSession?.permission?.[module] ? true : false;
    }
  };

  if (isLogin()) {
    if (isAllowAcceess()) {
      return children;
    }
    return <Navigate to="/" replace />;
  }
  return <Navigate to="/login" replace />;
};

export default AccessControl;
