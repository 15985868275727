import { apiCall } from "./servicesCall";

export const ROLE_SERVICES = {
  getRole(data) {
    return apiCall("/role", data);
  },
  createRole(data) {
    return apiCall("/role/create", data);
  },
  deleteRole(data) {
    return apiCall("/role/delete", data);
  },
  changeStatus(data) {
    return apiCall("/role/change/status", data);
  },
  editRole(data) {
    return apiCall("/role/edit", data);
  },
  updatRole(data) {
    return apiCall("/role/update", data);
  },
  getRoleList(data) {
    return apiCall("/role/list", data);
  },
};
