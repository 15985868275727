import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import EventUserProfile from "./EventUserProfile";
import EventUserAttend from "./EventUserAttend";
import EventUserHosted from "./EventUserHosted";
import EventUserRegister from "./EventUserRegister";
import EventUserOrganizations from "./EventUserOrganizations";
import EventUserSpeakers from "./EventUserSpeakers";

const EventUserTabs = () => {
  const { userId, tabType } = useParams();
  const userTabs = useMemo(
    () => ({
      profile: "Profile",
      organizations: "organizations",
      "hosted-events": "Hosted Events",
      "speakers-events": "Speakers Events",
      "attended-events": "Attended Events",
      "registered-events": "Registered Events",
    }),
    []
  );

  const [ToggleState, setToggleState] = useState();

  useEffect(() => {
    setToggleState(userTabs?.[tabType] ? tabType : "profile");
  }, [tabType, userTabs]);

  return (
    <Box className="managepro6ix-layout-page-content">
      <div className="managepro6ix-layout-page-content-header">
        <div className="managepro6ix-layout-page-content-header-left">
          <div className="managepro6ix-layout-page-content-header-menu">
            {Object.keys(userTabs).map((item, key) => (
              <Link
                key={key}
                to={`/events/users/${userId}/${item}`}
                className={`managepro6ix-layout-page-content-header-menu-link ${
                  ToggleState === item ? "active" : ""
                }`}
              >
                {userTabs[item]}
              </Link>
            ))}
          </div>
        </div>
      </div>
      {ToggleState === "profile" ? (
        <EventUserProfile />
      ) : ToggleState === "organizations" ? (
        <EventUserOrganizations />
      ) : ToggleState === "hosted-events" ? (
        <EventUserHosted />
      ) : ToggleState === "speakers-events" ? (
        <EventUserSpeakers />
      ) : ToggleState === "attended-events" ? (
        <EventUserAttend />
      ) : ToggleState === "registered-events" ? (
        <EventUserRegister />
      ) : null}
    </Box>
  );
};

export default EventUserTabs;
