import React from "react";
import { Box, Typography, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ShowProfileModel(props) {
  const { setOpen, open, data } = props;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" className="popupheader">
          {data?.name}
        </Typography>
        <Typography
          id="modal-modal-title"
          sx={{
            textAlign: "right",
            fontSize: "12px",
            paddingTop: "5px",
            color: "#bdbdbd",
          }}
        >
          {data?.work}, {data?.jobTitle}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{
            mt: 3,
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            gap: "5px",
          }}
        >
          <span>Email : {data?.email || "-"}</span>
          <span>Phone: {data?.phoneNumber || "-"}</span>
        </Typography>
      </Box>
    </Modal>
  );
}
