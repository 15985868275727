import React, { useEffect, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Grid,
  Box,
  TablePagination,
} from "@mui/material";
import DataTable from "../../Table/DataTable";
import { Link } from "react-router-dom";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

import Moment from "moment";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import { AI_SERVICES } from "../../../services";
import FullPageProgress from "../../common/FullPageProgress";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AiTopicsCSV } from "./AiTopicsCSV";

export default function AiTopics(props) {
  const { module } = props;

  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();
  const [topicList, setTopicList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const defaultFilter = {
    page: 0,
    limit: 10,
    name: "",
    createdAtTo: null,
    createdAtFrom: null,
  };
  const [filter, setFilter] = useState(defaultFilter);
  const rowsPerPageOptions = [10, 50, 100, 250, 500, 1000, 2000, 5000];
  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions?.view) return;

    setLoader(true);
    setDataFetched(true);

    let parma = { ...filter };
    parma["userId"] = userData.userId;

    if (parma?.createdAtTo) {
      parma.createdAtTo = new Date(parma?.createdAtTo);
      parma.createdAtTo.setHours(23);
      parma.createdAtTo.setMinutes(59);
      parma.createdAtTo.setSeconds(59);
    }

    AI_SERVICES.getTopics(parma)
      .then((data) => {
        setTopicList([]);
        if (data.code === 200) {
          setTopicList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, modulePermissions, filter]);

  const handleChangePage = (event, newPage) => {
    setFilter((old) => ({
      ...old,
      page: newPage,
    }));
    setDataFetched(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter((old) => ({
      ...old,
      limit: parseInt(event.target.value, 10),
      page: 0,
    }));
    setDataFetched(false);
  };

  const onChangeFilter = (name, value) => {
    let f = { ...filter };
    f[name] = value;
    setFilter(f);
  };
  const applyFilter = () => {
    setFilter((old) => ({
      ...old,
      page: 0,
    }));
    setDataFetched(false);
  };
  const clearFilter = () => {
    setFilter(defaultFilter);
    setDataFetched(false);
  };

  const columns = [
    {
      name: "sno",
      label: "S.No.",
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "conversation",
      label: "Conversation",
      options: {
        customBodyRender: (value) => {
          return (
            <Link className="text-link" to={`/ai/conversation/${value?._id}`}>
              {value?.name}
            </Link>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Created On",
    },

    {
      name: "updatedAt",
      label: "Last Updated",
    },
  ];
  const customOption = {
    pagination: false,
    sort: false,
  };

  return (
    <>
      {loader && <FullPageProgress />}

      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              6ix AI Topics
            </div>
          </div>
          <div className="managepro6ix-layout-page-content-header-action">
            {topicList?.length > 0 ? (
              <AiTopicsCSV listData={topicList} />
            ) : null}
          </div>
        </div>

        <Accordion>
          <AccordionSummary
            sx={{ marginTop: 2, width: "100px" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ textTransform: "uppercase", fontSize: "14px" }}>
              Filters
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginTop: 2 }}>
            <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label="Name"
                  value={filter?.name}
                  onChange={(e) => onChangeFilter("name", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Created Date From"
                    format="MMM DD YYYY"
                    value={filter?.createdAtFrom}
                    onChange={(newValue) => {
                      console.log(newValue, "newValue");
                      onChangeFilter(
                        "createdAtFrom",
                        newValue?.$d ? newValue?.$d : null
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Created Date To"
                    format="MMM DD YYYY"
                    value={filter?.createdAtTo}
                    minDate={dayjs(filter?.createdAtFrom)}
                    onChange={(newValue) => {
                      onChangeFilter(
                        "createdAtTo",
                        newValue?.$d ? newValue?.$d : null
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item sx={{ marginTop: 1 }}>
                <button
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-primary"
                  onClick={applyFilter}
                >
                  Search
                </button>
              </Grid>
              <Grid item sx={{ marginTop: 1 }}>
                <button
                  type="button"
                  className="managepro6ix-btn managepro6ix-btn-primary"
                  onClick={clearFilter}
                >
                  Clear
                </button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <DataTable
          columns={columns}
          data={topicList.map((item, key) => {
            return {
              sno: filter?.page * filter?.limit + key + 1,
              name: item.name,
              conversation: { name: item.conversation, _id: item?._id },
              updatedAt: item?.updatedAt
                ? Moment(item?.updatedAt).format("MMM DD, YYYY : hh:mm A")
                : "-",
              createdAt: Moment(item?.createdAt).format(
                "MMM DD, YYYY : hh:mm A"
              ),
              id: item?._id,
            };
          })}
          customOption={customOption}
        />

        <TablePagination
          component="div"
          count={totalRows}
          page={filter.page}
          onPageChange={handleChangePage}
          rowsPerPage={filter.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </Box>
    </>
  );
}
