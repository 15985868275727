import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SESSION_AUTH } from "../../../helpers/auth";
import { ANALYTICS_SERVICES } from "../../../services";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";

export default function EventReplayViews() {
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [loader, setLoader] = useState(true);

  const [chartOptions, setChartOptions] = useState("");

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [userSession]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoader(true);

    let start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 999);

    let yesterdayStart = new Date(start);
    let yesterdayEnd = new Date(end);

    yesterdayStart.setHours(0, 0, 0, 0);
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    yesterdayEnd.setHours(23, 59, 59, 999);
    yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);

    let days7Start = new Date(start);
    let days7End = new Date(end);
    days7Start.setHours(0, 0, 0, 0);
    days7Start.setDate(days7Start.getDate() - 7);
    let days30Start = new Date(start);
    days30Start.setHours(0, 0, 0, 0);
    days30Start.setDate(days30Start.getDate() - 30);
    let days90Start = new Date(start);
    days90Start.setHours(0, 0, 0, 0);
    days90Start.setDate(days90Start.getDate() - 90);
    let days180Start = new Date(start);

    days180Start.setHours(0, 0, 0, 0);
    days180Start.setDate(days180Start.getDate() - 180);

    let yearStart = new Date(start);

    yearStart.setHours(0, 0, 0, 0);
    yearStart.setFullYear(yearStart.getFullYear() - 1);

    ANALYTICS_SERVICES.eventReplayViews({
      userId: userData.userId,
      today: { start: start, end: end },
      yesterday: { start: yesterdayStart, end: yesterdayEnd },
      week: { start: days7Start, end: days7End },
      month: { start: days30Start, end: end },
      quarter: { start: days90Start, end: end },
      halfYear: { start: days180Start, end: end },
      year: { start: yearStart, end: end },
    })
      .then((data) => {
        if (data.code === 200) {
          let options = {
            chart: {
              plotBackgroundColor: "transparent",
              backgroundColor: "transparent",
              plotBorderWidth: 0,
              plotShadow: false,
              type: "line",
            },
            colors: ["#F5EACA", "#DBB163", "#A78F77", "#60574D", "#575757"],
            title: {
              text: "Per Event Average",
              align: "center",
              verticalAlign: "bottom",
              y: 30,
              style: {
                color: "white",
              },
            },
            xAxis: {
              categories: [
                "Today",
                "Yesterday",
                "Last 7 days",
                "Last 30 days",
                "Last 90 days",
                "Last 180 days",
                "Last one year",
              ],
              crosshair: true,

              labels: {
                style: {
                  color: "white",
                },
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Views",
                style: {
                  color: "white",
                },
              },
              labels: {
                style: {
                  color: "white",
                },
              },
              gridLineColor: "#4e4e4e",
            },

            tooltip: {
              headerFormat: "<span>{point.x}</span><br/>",
              pointFormat: "Views: <b>{point.y}</b>",
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
              },
            },
            legend: {
              itemStyle: {
                color: "white",
                fontWeight: "bold",
              },
              itemHoverStyle: {
                color: "#e3c050",
              },
            },
            series: [
              {
                data: [
                  data?.data?.today,
                  data?.data?.yesterday,
                  data?.data?.week,
                  data?.data?.month,
                  data?.data?.quarter,
                  data?.data?.halfyear,
                  data?.data?.year,
                ],
                showInLegend: false,
              },
            ],
          };
          setChartOptions(options);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION]);

  if (loader) {
    return <FullPageProgress />;
  }
  return (
    <>
      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              Events Replay views
            </div>
          </div>
        </div>

        <Grid
          style={{ marginTop: "10px" }}
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            {chartOptions && (
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="caption">
              This graph presents the total number of views for event replays,
              but only for events that went live within the selected timeline.
              It doesn't count replay views for events outside this period.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
