import React, { useRef, useEffect, useState } from "react";
import { GetCity, GetCountries, GetState } from "react-country-state-city";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import "react-tagsinput/react-tagsinput.css";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";
import { EVENTS_SERVICES } from "../../../services";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";

import "react-datepicker/dist/react-datepicker.css";
import { ConfirmModal } from "../../Modals/ConfirmModal";

export default function SubscriptionBillingModal(props) {
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { onClose, onSave, open, orgAccount, module } = props;
  const [modulePermissions, setModulePermissions] = useState();
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  const dataFetched = useRef(false);
  const [loader, setLoader] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [cityDisable, setCityDisable] = useState(false);
  const [stateDisable, setStateDisable] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [contractLink, setContractLink] = useState(false);

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
      GetCountries().then((result) => {
        setCountriesList(result);
      });
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (userData && !modulePermissions?.add) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);
  useEffect(() => {
    if (!userData || dataFetched.current) return;
    dataFetched.current = true;
    try {
      setLoader(true);
      let parma = {
        userId: userData?.userId,
        accountId: orgAccount?._id,
      };
      EVENTS_SERVICES.getBillingSetup(parma)
        .then((data) => {
          if (data.code === 200) {
            if (data.message) {
              ToastManager.addToast(data.message);
            }
            setName(data?.data?.name);
            setEmail(data?.data?.email);
            setLine1(data?.data?.line1 || "");
            setLine2(data?.data?.line2 || "");
            setCity(data?.data?.city || "");
            setState(data?.data?.state || "");
            setCountry(data?.data?.country || "");
            setPostal_code(data?.data?.postal_code || "");
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
          }
        })
        .catch((error) => {
          ToastManager.addToast(error.message);
        })
        .finally(() => {
          setLoader(false);
        });
    } catch (error) {
      ToastManager.addToast(error.message);
      setLoader(false);
    }
  }, [orgAccount, LOGOUT_USER_SESSION, userData]);

  const countryChange = (value) => {
    setCountry(value);
    setState("");
    setCity("");
    setPostal_code("");
    setStatesList([]);
    setCityList([]);
  };
  const stateChange = (value) => {
    setState(value);
    setCity("");
    setCityList([]);
  };

  useEffect(() => {
    if (!country || !countriesList?.[0]) return;
    let selected = countriesList.filter((item) => {
      return item.iso2 === country;
    });
    if (selected?.[0]) {
      GetState(selected?.[0]?.id).then((result) => {
        setStatesList(result);
        setStateDisable(result.length > 0 ? false : true);
        setCityDisable(result.length > 0 ? false : true);
      });
    }
  }, [country, countriesList]);

  useEffect(() => {
    if (!country || !state || !countriesList?.[0] || !statesList?.[0]) return;

    let selectedCountry = countriesList.filter((item) => {
      return item.iso2 === country;
    });
    let selectedState = statesList.filter((item) => {
      return item.name === state;
    });

    if (selectedCountry?.[0] && selectedState?.[0]) {
      GetCity(selectedCountry?.[0]?.id, selectedState?.[0]?.id).then(
        (result) => {
          setCityList(result);
          setCityDisable(result.length > 0 ? false : true);
        }
      );
    }
  }, [country, state, countriesList, statesList]);

  const updateBillingSetup = async () => {
    if (!name) {
      ToastManager.addToast("Please enter name");
      return false;
    }
    if (!email) {
      ToastManager.addToast("Please enter email address");
      return false;
    }

    setOpenConfirm(true);
  };
  const confirmRequestCancel = () => {
    setOpenConfirm(false);
    onClose();
  };

  const confirmRequestAccept = () => {
    setOpenConfirm(false);
    setLoader(true);

    try {
      let parma = {
        userId: userSession?.userId,
        accountId: orgAccount?._id,
        name: name,
        email: email,
        line1: line1,
        line2: line2,
        city: city,
        state: state,
        country: country,
        postal_code: postal_code,
        contractLink: contractLink,
      };
      EVENTS_SERVICES.updateBillingSetup(parma)
        .then((data) => {
          if (data.code === 200) {
            ToastManager.addToast(data.message);
            if (data?.data?.link) {
              navigator.clipboard.writeText(data?.data?.link);
              ToastManager.addToast(
                "Payment link has been copied to your clipboard!"
              );
            }
            onSave();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
          }
        })
        .catch((error) => {
          ToastManager.addToast(error.message);
        })
        .finally(() => {
          setLoader(false);
        });
    } catch (error) {
      ToastManager.addToast(error.message);
      setLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog custom-dialog-full"
      >
        <DialogTitle className="text-center">
          {orgAccount?.subscription?.customer
            ? "Update Billing Setup"
            : "Create Billing Setup"}{" "}
          <br /> <small> {orgAccount?.orgName}</small>
        </DialogTitle>
        <DialogContent>
          {loader && <FullPageProgress />}

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="event-subscription"
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contractLink}
                      onChange={() => {
                        setContractLink(!contractLink);
                      }}
                    />
                  }
                  label="Send billing contract setup invoice ($1)"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  xs={12}
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <TextField
                  type="email"
                  xs={12}
                  label="Email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  xs={12}
                  label="Address"
                  name="address"
                  value={line1}
                  onChange={(e) => setLine1(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  xs={12}
                  label="Address 2"
                  name="address"
                  value={line2}
                  onChange={(e) => setLine2(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="billing-address-country"
                  className="account-settings-page-content-box-lable"
                >
                  Country
                </InputLabel>

                <Select
                  id="billing-address-country"
                  name="country"
                  className="custom-select"
                  value={country}
                  onChange={(e) => countryChange(e.target.value)}
                  displayEmpty
                >
                  {countriesList.map((item, key) => (
                    <MenuItem key={key} value={item?.iso2}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!stateDisable && (
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="billing-address-state"
                    className="account-settings-page-content-box-lable"
                  >
                    State
                  </InputLabel>
                  <Select
                    id="billing-address-state"
                    name="state"
                    className="custom-select"
                    value={state}
                    onChange={(e) => stateChange(e.target.value)}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span>Select</span>;
                      }
                      return selected;
                    }}
                  >
                    {statesList.map((item, key) => (
                      <MenuItem key={key} value={item?.name}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!cityDisable && (
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="billing-address-city"
                    className="account-settings-page-content-box-lable"
                  >
                    City
                  </InputLabel>
                  <Select
                    id="billing-address-city"
                    name="city"
                    className="custom-select"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span>Select</span>;
                      }
                      return selected;
                    }}
                  >
                    {cityList.map((item, key) => (
                      <MenuItem key={key} value={item?.name}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  xs={12}
                  label="ZIP / Postal Code"
                  name="postal_code"
                  value={postal_code}
                  onChange={(e) => setPostal_code(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="custom-dialog-action">
          <button
            type="button"
            className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-primary"
            onClick={updateBillingSetup}
          >
            {orgAccount?.subscription?.customer ? "Update" : "Create"}
          </button>
          <button
            onClick={onClose}
            className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-secondary"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>

      {openConfirm && (
        <ConfirmModal
          title={orgAccount?.orgName}
          message={`Are you sure for ${
            orgAccount?.subscription?.customer ? " update " : " create "
          } billing setup`}
          request={openConfirm}
          cancel={confirmRequestCancel}
          accept={confirmRequestAccept}
        />
      )}
    </>
  );
}
