import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import "react-tagsinput/react-tagsinput.css";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";
import { EVENTS_SERVICES } from "../../../services";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import {
  SUBSCRIPTION_STATUS_LIST,
  SUBSCRIPTION_MANAGE,
} from "../../../helpers/constants";

export default function CreateSubscriptionModal(props) {
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { onClose, onSave, open, orgAccount, module, SUBSCRIPTION_PLAN } =
    props;
  const stripe = useStripe();
  const [modulePermissions, setModulePermissions] = useState();
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const [manage, setManage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [status, setStatus] = useState("");
  const [lastSubscription, setLastSubscription] = useState("");

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (userData && !modulePermissions?.add) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);
  useEffect(() => {
    /* eslint-disable */
    if (!manage) {
      setManage(
        orgAccount?.subscription?.manage || SUBSCRIPTION_MANAGE?.manual?.value
      );
    }
    setStartDate(orgAccount?.subscription?.startDate || null);
    setEndDate(orgAccount?.subscription?.endDate || null);
    setStatus(orgAccount?.subscription?.status || "");
    choosePlan(orgAccount?.subscription?.plan?.priceId);
    setLastSubscription(orgAccount?.subscription);
  }, [orgAccount, manage]);

  const choosePlan = (priceId) => {
    let plan = "";
    if (priceId) {
      for (const [key] of Object.entries(SUBSCRIPTION_PLAN)) {
        if (SUBSCRIPTION_PLAN[key].priceId === priceId) {
          plan = SUBSCRIPTION_PLAN[key];
        }
      }
    }
    setSelectedPlan(plan);
  };
  const chooseManage = (value) => {
    if (
      lastSubscription?.type === "paid" &&
      lastSubscription?.manage === SUBSCRIPTION_MANAGE?.stripe?.value
    ) {
      ToastManager.addToast(
        "Kindly modify the free plan, and thereafter, you can switch the subscription type"
      );
    } else {
      setManage(value);
    }
  };
  const updateSubscription = async () => {
    if (!selectedPlan || (selectedPlan && !selectedPlan?.productId)) {
      ToastManager.addToast("Please choose plan");
      return false;
    }
    if (manage === SUBSCRIPTION_MANAGE.manual.value) {
      if (!startDate) {
        ToastManager.addToast("Please select start date");
        return false;
      }
      if (!endDate) {
        ToastManager.addToast("Please select end date");
        return false;
      }
      if (!status) {
        ToastManager.addToast("Please select status");
        return false;
      }
    }

    setOpenConfirm(true);
  };
  const confirmRequestCancel = () => {
    setOpenConfirm(false);
    onClose();
  };

  const confirmRequestAccept = () => {
    setOpenConfirm(false);
    setLoader(true);

    try {
      let parma = {
        userId: userSession?.userId,
        plan: selectedPlan,
        accountId: orgAccount?._id,
        manage: manage,
      };
      if (manage === SUBSCRIPTION_MANAGE?.manual?.value) {
        parma["startDate"] = startDate;
        parma["endDate"] = endDate;
        parma["status"] = status;
      }
      EVENTS_SERVICES.updateSubscription(parma)
        .then(async (data) => {
          if (data.code === 200) {
            if (data?.data?.clientSecret) {
              const confirmPayment = await stripe?.confirmCardPayment(
                data.data?.clientSecret
              );

              if (confirmPayment?.error) {
                ToastManager.addToast({
                  title: confirmPayment.error.message,
                });
              } else {
                ToastManager.addToast({
                  title: data.message,
                });
              }
            } else {
              ToastManager.addToast({
                title: data.message,
              });
            }
            setTimeout(function () {
              onSave();
            }, 1000);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
            setLoader(false);
          }
        })
        .catch((error) => {
          ToastManager.addToast(error.message);
          setLoader(false);
        });
    } catch (error) {
      ToastManager.addToast(error.message);
      setLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog custom-dialog-full"
      >
        <DialogTitle className="text-center">
          {orgAccount?.subscription?.status
            ? "Update Susbscription"
            : "Create Subscription"}{" "}
          <br /> <small> {orgAccount?.orgName}</small>
        </DialogTitle>
        <DialogContent>
          {loader && <FullPageProgress />}

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="event-subscription"
          >
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <FormLabel id="subscription-manage-label">Manage by</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="subscription-manage-label"
                  name="rsubscription-type-select-label"
                  defaultValue={manage}
                  value={manage}
                  onChange={(e) => {
                    chooseManage(e?.target?.value);
                  }}
                >
                  {Object.keys(SUBSCRIPTION_MANAGE).map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={SUBSCRIPTION_MANAGE[item]?.value}
                      control={<Radio />}
                      label={SUBSCRIPTION_MANAGE[item]?.name}
                    ></FormControlLabel>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="subscription-plan-select-label">
                  Select Plan
                </InputLabel>
                <Select
                  labelId="subscription-plan-select-label"
                  label="Select Plan"
                  name="plan"
                  value={selectedPlan?.priceId || ""}
                  onChange={(e) => choosePlan(e.target.value)}
                >
                  {Object.keys(SUBSCRIPTION_PLAN).map((item, index) => (
                    <MenuItem
                      key={index}
                      value={SUBSCRIPTION_PLAN[item]?.priceId}
                    >
                      {SUBSCRIPTION_PLAN[item]?.name} - ( $
                      {SUBSCRIPTION_PLAN[item]?.price}) - per investor profile
                      ($
                      {SUBSCRIPTION_PLAN[item]?.unit?.value || 0})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {lastSubscription?.manage === SUBSCRIPTION_MANAGE?.stripe?.value ||
            manage === SUBSCRIPTION_MANAGE?.manual?.value ? (
              <>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        readOnly={SUBSCRIPTION_MANAGE?.stripe?.value === manage}
                        label="Subscription Start Date"
                        format="MMM DD YYYY hh:mm A"
                        value={startDate ? dayjs(startDate) : null}
                        onChange={(newValue) => {
                          setStartDate(newValue?.$d ? newValue?.$d : null);
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        readOnly={SUBSCRIPTION_MANAGE?.stripe?.value === manage}
                        label="Subscription End Date"
                        format="MMM DD YYYY hh:mm A"
                        value={endDate ? dayjs(endDate) : null}
                        minDate={startDate ? dayjs(startDate) : null}
                        onChange={(newValue) => {
                          setEndDate(newValue?.$d ? newValue?.$d : null);
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="subscription-plan-select-label">
                      Select Status
                    </InputLabel>
                    <Select
                      labelId="subscription-plan-select-label"
                      label="Select Plan"
                      name="plan"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      disabled={SUBSCRIPTION_MANAGE?.stripe?.value === manage}
                    >
                      {Object.keys(SUBSCRIPTION_STATUS_LIST).map(
                        (item, index) => (
                          <MenuItem
                            key={index}
                            value={SUBSCRIPTION_STATUS_LIST[item]?.value}
                          >
                            {SUBSCRIPTION_STATUS_LIST[item]?.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions className="custom-dialog-action">
          <button
            type="button"
            className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-primary"
            onClick={updateSubscription}
          >
            {orgAccount?.subscription?.status ? "Update " : "Create "}
          </button>
          <button
            onClick={onClose}
            className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-secondary"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
      {openConfirm && (
        <ConfirmModal
          title={orgAccount?.orgName}
          message={`Are you sure for ${
            orgAccount?.subscription?.status ? " update " : " create "
          } subscription`}
          request={openConfirm}
          cancel={confirmRequestCancel}
          accept={confirmRequestAccept}
        />
      )}
    </>
  );
}
