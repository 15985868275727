export const isValidEmail = (email) => {
  let emailValidRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(emailValidRegex)) {
    let atpos = email.indexOf("@");
    let dotpos = email.lastIndexOf(".");
    if (atpos < 1 || dotpos - atpos < 2) {
      return false;
    }
    return true;
  }
  return false;
};

export const checkModuleActionPermission = (
  data = {},
  module = "",
  action = ""
) => {
  if (data?.permission) {
    if (data?.permission?.[module]) {
      return data?.permission?.[module]?.[action] === true ? true : false;
    }
  }
  return false;
};
export const getModuleAllPermissions = (data = {}, module = "") => {
  if (data?.permission) {
    if (data?.permission?.[module]) {
      const permission = data?.permission?.[module];
      let returnPermission = {};
      for (const property in permission) {
        if (permission[property] === true) {
          returnPermission[property] = true;
        }
      }
      return returnPermission;
    }
  }
  return false;
};

export const SAMPLE_CSV_PARTICIPANT =
  "https://6ix-events-pro.s3.amazonaws.com/6ixvideo/file/sample-participants-user.csv";

export const CSVToJSONConvert = (data, delimiter = ",") => {
  var lines = data.split("\n");
  var result = [];
  var headers = lines[0].split(",");
  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");
    for (var j = 0; j < headers.length; j++) {
      obj[headers[j].trim()] = currentline[j]
        ? currentline[j].replace(/(?:\\[rn]|[\r\n]+)+/g, "")
        : "";
    }
    result.push(obj);
  }

  return result;
};

export const ConvertNameFirstLast = (name) => {
  let firstName = name;
  let lastName = "";
  if (firstName.indexOf(" ") > -1) {
    let names = firstName.split(" ");
    firstName = names[0];
    lastName = names?.length > 1 ? names[names.length - 1] : "";
  } else if (firstName.indexOf(".") > -1) {
    let names = firstName.split(".");
    firstName = names[0];
    lastName = names?.length > 1 ? names[names.length - 1] : "";
  }
  if (firstName) firstName = firstName[0].toUpperCase() + firstName.slice(1);
  if (lastName) lastName = lastName[0].toUpperCase() + lastName.slice(1);

  return { firstName, lastName };
};

export const createCSVDownload = (filename, rows) => {
  var processRow = function (row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] ? row[j].toString() : "";
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  var csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
