import { apiCall } from "./servicesCall";

export const AI_SERVICES = {
  getDashboard(data) {
    return apiCall("/ai/dashboard", data);
  },
  getTopics(data) {
    return apiCall("/ai/topics", data);
  },
  getConversations(data) {
    return apiCall("/ai/conversations", data);
  },
};
