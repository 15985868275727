import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import "./datatable.css";

const options = {
  download: "false",
  filter: "false",
  print: "false",
  viewColumns: "false",
  rowHover: true,
  searchAlwaysOpen: false,
  selectableRowsOnClick: false,
  selectableRows: "none",
  sort: true,
  resizableColumns: false,
  selectableRowsHeader: false,
  search: false,
  responsive: "standard",
};

export default function DataTable(props) {
  const { columns, data, customToolbar, customOption } = props;

  useEffect(() => {
    if (customOption) {
      for (let key in customOption) {
        options[key] = customOption[key];
      }
    }
  }, [customOption]);
  return (
    <div className="datatable" key={data.id}>
      <MUIDataTable
        data={data}
        columns={columns}
        options={{
          ...options,
          ...(typeof customToolbar === "function" && { customToolbar }),
        }}
      />
    </div>
  );
}
