import { apiCall } from "./servicesCall";

export const ANALYTICS_SERVICES = {
  getNewUser(data) {
    return apiCall("/analytics/user/new", data);
  },
  funnelEvent(data) {
    return apiCall("/analytics/event/funnel", data);
  },
  eventDistribution(data) {
    return apiCall("/analytics/event/distribution", data);
  },
  eventHost(data) {
    return apiCall("/analytics/event/host", data);
  },
  eventLive(data) {
    return apiCall("/analytics/event/live", data);
  },
  eventParticipant(data) {
    return apiCall("/analytics/event/participant", data);
  },
  eventInvitation(data) {
    return apiCall("/analytics/event/invitation", data);
  },
  eventReplay(data) {
    return apiCall("/analytics/event/replay", data);
  },
  eventReplayViews(data) {
    return apiCall("/analytics/event/replay/views", data);
  },
  eventAttendes(data) {
    return apiCall("/analytics/event/attendes", data);
  },
  eventAttendesAverage(data) {
    return apiCall("/analytics/event/attendes/average", data);
  },
  eventAttendesJoin(data) {
    return apiCall("/analytics/event/attendes/join", data);
  },
};
