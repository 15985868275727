import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/6ixlogo.png";

const HeaderLogo = () => {
  return (
    <React.Fragment>
      <div className="managepro6ix-layout-header-logo">
        <Link target="_blank" rel="noreferrer" title="6ix Matrix" to="/">
          <img
            className="managepro6ix-layout-header-logo-img"
            src={logo}
            alt="logo"
          />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default HeaderLogo;
