import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AppToastContainer = () => {
  return (
    <ToastContainer
      closeOnClick={true}
      hideProgressBar={true}
      autoClose={3000}
      theme={"dark"}
      position="bottom-left"
    />
  );
};
export const ToastManager = {
  addToast(message) {
    toast(message);
    return;
  },
};
