import React, { useEffect, useState } from "react";
import { Avatar, TablePagination } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { EVENTS_SERVICES } from "../../../../services";
import { ToastManager } from "../../../common/ToastContainer";
import FullPageProgress from "../../../common/FullPageProgress";
import { SESSION_AUTH } from "../../../../helpers/auth";
import Moment from "moment";
import DataTable from "../../../Table/DataTable";
import {
  SUBSCRIPTION_STATUS_LIST,
  SUBSCRIPTION_MANAGE,
} from "../../../../helpers/constants";

const EventUserOrganizations = () => {
  const [loader, setLoader] = useState(false);
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [planFetched, setPlanFetched] = useState(false);
  const { userId } = useParams();
  const [orgList, setOrgList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [SUBSCRIPTION_PLAN, setSUBSCRIPTION_PLAN] = useState("");

  useEffect(() => {
    if (!userData || planFetched) return;
    setLoader(true);
    setPlanFetched(true);
    let parma = { userId: userData.userId };
    EVENTS_SERVICES.getPlans(parma)
      .then((data) => {
        if (data.code === 200) {
          setSUBSCRIPTION_PLAN(data?.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, planFetched, LOGOUT_USER_SESSION]);

  const getCurrentPlan = (product) => {
    let plan;
    if (product) {
      for (const [key] of Object.entries(SUBSCRIPTION_PLAN)) {
        if (SUBSCRIPTION_PLAN[key].productId === product) {
          plan = SUBSCRIPTION_PLAN[key];
        }
      }
    }
    return plan;
  };

  const defaultFilter = {
    page: 0,
    limit: 10,
    orgOwnerId: userId,
  };
  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [userSession]);

  useEffect(() => {
    if (!userData || dataFetched) return;

    setLoader(true);
    setDataFetched(true);

    let parma = { ...filter };
    parma["userId"] = userData.userId;
    EVENTS_SERVICES.getOrgazination(parma)
      .then((data) => {
        setOrgList([]);
        if (data.code === 200) {
          setOrgList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, filter]);
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    { name: "sno", label: "S.No." },
    {
      name: "orgLogo",
      label: "Logo",
      options: {
        customBodyRender: (item) => {
          return item?.logo ? (
            <Avatar
              style={{ background: item?.color }}
              src={item?.logo}
              alt=""
            />
          ) : null;
        },
      },
    },
    {
      name: "orgName",
      label: "Name",
      options: {
        customBodyRender: (item) => {
          return (
            <Link
              to={`${process.env.REACT_APP_EVENT_PLATFORM}/company/${item?.slug}`}
              target="_blank"
              className="text-link"
            >
              {item?.name}
            </Link>
          );
        },
      },
    },
    {
      name: "owner",
      label: "Owner",
      options: {
        customBodyRender: (value) => {
          return <span>{value?.name}</span>;
        },
      },
    },
    { name: "createdAt", label: "Created" },
    { name: "teams", label: "Teams" },
    { name: "events", label: "Events" },
    {
      name: "subscriptionStatus",
      label: "Subscription",
      options: {
        customBodyRender: (value) => {
          switch (value) {
            case 1:
              return SUBSCRIPTION_STATUS_LIST?.[1]?.name;
            case 2:
              return SUBSCRIPTION_STATUS_LIST?.[2]?.name;
            case 3:
              return SUBSCRIPTION_STATUS_LIST?.[3]?.name;
            case 4:
              return SUBSCRIPTION_STATUS_LIST?.[4]?.name;
            case 5:
              return SUBSCRIPTION_STATUS_LIST?.[5]?.name;
            case 6:
              return SUBSCRIPTION_STATUS_LIST?.[6]?.name;
            default:
              return "-";
          }
        },
      },
    },
    {
      name: "subscriptionPlan",
      label: "Plan",
      options: {
        customBodyRender: (plan) => {
          return getCurrentPlan(plan?.product)?.name || "";
        },
      },
    },
    {
      name: "subscriptionManage",
      label: "Manage",
      options: {
        customBodyRender: (item) => {
          return (
            <div>
              {item?.manage} - {item?.type}
            </div>
          );
        },
      },
    },
    { name: "subscriptioStartDate", label: "Subscription Start" },
    { name: "subscriptioEndDate", label: "Subscription End" },

    {
      name: "action",
      label: "Transaction",
      options: {
        customBodyRender: (item) => {
          return (
            <>
              {item?.subscription?.customer ? (
                <>
                  <Link
                    to={`/events/organization/${item._id}/transactions`}
                    className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary m-l-5"
                  >
                    View Transaction
                  </Link>
                </>
              ) : null}
            </>
          );
        },
      },
    },
  ];
  const handleChangePage = (event, newPage) => {
    setFilter((old) => ({
      ...old,
      page: newPage,
    }));
    setDataFetched(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter((old) => ({
      ...old,
      limit: event.target.value,
      page: 0,
    }));
    setDataFetched(false);
  };

  const customOption = {
    pagination: false,
  };
  return (
    <>
      {loader && <FullPageProgress />}
      <div className="managepro6ix-table-center">
        <DataTable
          columns={columns}
          customOption={customOption}
          data={orgList.map((item, key) => {
            return {
              action: item,
              sno: filter?.page * filter?.limit + key + 1,
              orgName: { name: item.orgName, slug: item.orgSlug },
              orgLogo: { logo: item?.orgLogo, color: item?.orgLogoColor },
              owner: item.owner,
              teams: item.teams,
              events: item.events,
              createdAt: Moment(item?.createdAt).format(
                "MMM DD, YYYY : hh:mm A"
              ),
              id: item?._id,
              subscriptionStatus: item?.subscription?.status
                ? item?.subscription?.status
                : item?.subscription?.statusLabel
                ? item?.subscription?.statusLabel
                : 0,
              subscriptionPlan: item?.subscription?.plan,
              subscriptionManage: {
                manage: SUBSCRIPTION_MANAGE?.[item?.subscription?.manage]?.name,
                type: item?.subscription?.type,
              },
              subscriptioStartDate: item?.subscription?.startDate
                ? Moment(item?.subscription?.startDate).format(
                    "MMM DD, YYYY : hh:mm A"
                  )
                : "",
              subscriptioEndDate: item?.subscription?.endDate
                ? Moment(item?.subscription?.endDate).format(
                    "MMM DD, YYYY : hh:mm A"
                  )
                : "",
            };
          })}
        />
      </div>
      <TablePagination
        component="div"
        count={totalRows}
        page={filter.page}
        onPageChange={handleChangePage}
        rowsPerPage={filter.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={true}
        showLastButton={true}
      />
    </>
  );
};

export default EventUserOrganizations;
