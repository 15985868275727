import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward,
} from "@mui/icons-material";
import { BarChart } from "@mui/x-charts";
import FullPageProgress from "../../common/FullPageProgress";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { ToastManager } from "../../common/ToastContainer";
import { EVENTS_SERVICES } from "../../../services";
import dayjs from "dayjs";
import Moment from "moment";

import "../eventstyle.scss";

const eventColor = "#A78F77";
const attendeColor = "#DBB163";
const userColor = "#F5EACA";
const darkGray = "#1F1F1F";

export default function EventDashboard(props) {
  const { module } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [weekUsersData, setWeekUsersData] = useState({
    currentWeek: 0,
    percent: 0,
    lastWeek: 0,
  });
  const [weekEventsData, setWeekEventsData] = useState({
    currentWeek: 0,
    percent: 0,
    lastWeek: 0,
  });
  const [weekAttendeRegData, setWeekAttendeRegData] = useState({
    currentWeek: 0,
    percent: 0,
    lastWeek: 0,
  });

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions?.view) return;

    setLoader(true);
    setDataFetched(true);

    let parma = {
      page: 0,
      limit: 100,
      eventDateFrom: dayjs().startOf("day"),
      eventDateTo: dayjs().endOf("day"),
    };
    parma["userId"] = userData.userId;

    EVENTS_SERVICES.getEvents(parma)
      .then((data) => {
        setEventList([]);
        if (data.code === 200) {
          setEventList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });

    EVENTS_SERVICES.getDashboard(parma)
      .then((data) => {
        if (data.code === 200) {
          console.log(data, "data");
          if (data?.data?.user) {
            let Userpercent =
              (data?.data?.user?.currentWeek / data?.data?.user?.lastWeek) *
              100;
            setWeekUsersData({
              currentWeek: data?.data?.user?.currentWeek || 0,
              percent: Math.round(Userpercent) || 0,
              lastWeek: data?.data?.user?.lastWeek || 0,
            });
          }
          if (data?.data?.event) {
            let Eventpercent =
              (data?.data?.event?.currentWeek / data?.data?.event?.lastWeek) *
              100;
            setWeekEventsData({
              currentWeek: data?.data?.event?.currentWeek || 0,
              percent: Math.round(Eventpercent) || 0,
              lastWeek: data?.data?.event?.lastWeek || 0,
            });
          }
          if (data?.data?.attende) {
            let Eventpercent =
              (data?.data?.attende?.currentWeekReg /
                data?.data?.attende?.lastWeekReg) *
              100;
            setWeekAttendeRegData({
              currentWeek: data?.data?.attende?.currentWeekReg || 0,
              percent: Math.round(Eventpercent) || 0,
              lastWeek: data?.data?.attende?.lastWeekReg || 0,
            });
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, modulePermissions]);

  return (
    <Box className="managepro6ix-layout-page-content">
      {loader && <FullPageProgress />}

      <div className="managepro6ix-layout-page-content-header">
        <div className="managepro6ix-layout-page-content-header-left">
          <div className="managepro6ix-layout-page-content-header-heading">
            Event Dashboard
          </div>
        </div>
      </div>

      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={4} md={3}>
          <Grid item xs={12}>
            <Card
              onClick={() => navigate("/events/list")}
              sx={{
                height: 140,
                marginTop: "10px",
                backgroundColor: "#927A64",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#927a64e6",
                },
              }}
            >
              <CardContent>
                <Typography
                  className="text-white text-start"
                  sx={{ fontSize: 12 }}
                  color="text.white"
                  gutterBottom
                >
                  Weekly Events
                </Typography>
                <Typography
                  variant="h4"
                  className="text-white text-start"
                  component="div"
                >
                  {weekEventsData?.currentWeek}
                </Typography>
                {weekEventsData?.currentWeek > 0 ? (
                  <Box
                    variant="body2"
                    className="text-white d-flex items-center"
                    marginTop={1}
                  >
                    {weekEventsData?.percent >= 100 ? (
                      <>
                        <ArrowUpward width={20} height={20} />
                        {weekEventsData?.percent - 100}% Higher than last week
                      </>
                    ) : (
                      <>
                        <ArrowDownwardIcon width={20} height={20} />
                        {100 - weekEventsData?.percent}% Lower than last week
                      </>
                    )}
                  </Box>
                ) : null}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              sx={{
                height: 140,
                marginTop: "10px",
                backgroundColor: "#60574D",
                "&:hover": {
                  backgroundColor: "#60574de6",
                },
              }}
            >
              <CardContent>
                <Typography
                  className="text-white text-start"
                  sx={{ fontSize: 12 }}
                  color="text.white"
                  gutterBottom
                >
                  Weekly Attendees
                </Typography>
                <Typography
                  variant="h4"
                  className="text-white text-start"
                  component="div"
                >
                  {weekAttendeRegData?.currentWeek}
                </Typography>
                {weekAttendeRegData?.currentWeek > 0 ? (
                  <Box
                    variant="body2"
                    className="text-white d-flex items-center"
                    marginTop={1}
                  >
                    {weekAttendeRegData?.percent >= 100 ? (
                      <>
                        <ArrowUpward width={20} height={20} />
                        {weekAttendeRegData?.percent - 100}% Higher than last
                        week
                      </>
                    ) : (
                      <>
                        <ArrowDownwardIcon width={20} height={20} />
                        {100 - weekAttendeRegData?.percent}% Lower than last
                        week
                      </>
                    )}
                  </Box>
                ) : null}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              onClick={() => navigate("/events/users")}
              sx={{
                height: 140,
                marginTop: "10px",
                backgroundColor: "#575757",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#575757e6",
                },
              }}
            >
              <CardContent>
                <Typography
                  className="text-white text-start"
                  sx={{ fontSize: 12 }}
                  color="text.white"
                  gutterBottom
                >
                  Weekly Users
                </Typography>
                <Typography
                  variant="h4"
                  className="text-white text-start"
                  component="div"
                >
                  {weekUsersData?.currentWeek}
                </Typography>
                {weekUsersData?.currentWeek > 0 ? (
                  <Box
                    variant="body2"
                    className="text-white d-flex items-center"
                    marginTop={1}
                  >
                    {weekUsersData?.percent >= 100 ? (
                      <>
                        <ArrowUpward width={20} height={20} />
                        {weekUsersData?.percent - 100}% Higher than last week
                      </>
                    ) : (
                      <>
                        <ArrowDownwardIcon width={20} height={20} />
                        {100 - weekUsersData?.percent}% Lower than last week
                      </>
                    )}
                  </Box>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={5} sx={{ height: 440 }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              height: 440,
              marginTop: "10px",
              backgroundColor: darkGray,
              overflow: "hidden",
              padding: "20px",
            }}
          >
            <Typography className="items-left d-flex justify-content-left text-white graphheader">
              Weekly
            </Typography>

            <BarChart
              xAxis={[
                { scaleType: "band", data: ["Current Week", "Last Week"] },
              ]}
              series={[
                {
                  data: [weekEventsData.currentWeek, weekEventsData.lastWeek],
                  label: "Events",
                  color: eventColor,
                },
                {
                  data: [
                    weekAttendeRegData.currentWeek,
                    weekAttendeRegData.lastWeek,
                  ],
                  label: "Attendees",
                  color: attendeColor,
                },
                {
                  data: [weekUsersData.currentWeek, weekUsersData.lastWeek],
                  label: "Users",
                  color: userColor,
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{ height: 440, marginTop: { xs: "10px", md: 0 } }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              marginTop: "10px",
              backgroundColor: darkGray,
              overflow: "hidden",
              padding: "20px",
            }}
          >
            <Typography
              className="items-left d-flex justify-content-left text-white"
              marginBottom={2}
            >
              Today's Events
            </Typography>
            <h4>{totalRows}</h4>
          </Grid>
          <TableContainer component={Paper}>
            <Table className="dashboard-event-table">
              {eventList?.length > 0 && (
                <caption>
                  <Link className="text-link" to="/events/list">
                    <ArrowDownwardIcon />
                    Show All Events
                  </Link>
                </caption>
              )}
              <TableBody>
                {eventList.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.eventName}</TableCell>
                    <TableCell>
                      {Moment(row?.eventDate).format("hh:mm A")}
                    </TableCell>
                    <TableCell
                      sx={{ borderLeft: "1px solid ##4c4c4c", color: "#fff" }}
                    >
                      {row.eventHostBy}
                    </TableCell>
                    <TableCell
                      sx={{ borderLeft: "1px solid ##4c4c4c", color: "#fff" }}
                    >
                      <a
                        href={row?.eventMeetingLink}
                        target="_blank"
                        className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
                        rel="noreferrer"
                      >
                        View
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
