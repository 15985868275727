import React from "react";
import { Box } from "@mui/material";
import PromoDashboard from "../components/Promo/PromoDashboard/PromoDashboard";

export const PromoPage = () => {
  return (
    <Box className="managepro6ix-layout-page">
      <PromoDashboard />
    </Box>
  );
};

export default PromoPage;
