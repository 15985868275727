import React from "react";
import { Box } from "@mui/material";
import AiDashboard from "../components/Ai/AiDashboard/AiDashboard";

import AiConversation from "../components/Ai/AiConversation/AiConversation";
import AiTopics from "../components/Ai/AiTopics/AiTopics";

export const AiPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <AiDashboard module={module} />
    </Box>
  );
};

export const AiTopicPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <AiTopics module={module} />
    </Box>
  );
};
export const AiConversationPage = (props) => {
  const { module } = props;
  return (
    <Box className="managepro6ix-layout-page">
      <AiConversation module={module} />
    </Box>
  );
};

export default AiPage;
