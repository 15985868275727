import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward,
} from "@mui/icons-material";
import { BarChart } from "@mui/x-charts";
import FullPageProgress from "../../common/FullPageProgress";
import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { ToastManager } from "../../common/ToastContainer";
import { AI_SERVICES } from "../../../services";
import dayjs from "dayjs";
import Moment from "moment";

const topicColor = "#A78F77";
const darkGray = "#1F1F1F";

export default function EventDashboard(props) {
  const { module } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();
  const navigate = useNavigate();
  const [topicList, setTopicList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [weekConversationData, setWeekConversationData] = useState({
    currentWeek: 0,
    percent: 0,
    lastWeek: 0,
  });

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions?.view) return;

    setLoader(true);
    setDataFetched(true);

    let parma = {
      page: 0,
      limit: 10,
      createdAtFrom: dayjs().startOf("day"),
      createdAtTo: dayjs().endOf("day"),
    };
    parma["userId"] = userData.userId;

    AI_SERVICES.getTopics(parma)
      .then((data) => {
        setTopicList([]);
        if (data.code === 200) {
          setTopicList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });

    AI_SERVICES.getDashboard(parma)
      .then((data) => {
        if (data.code === 200) {
          if (data?.data?.topic) {
            let Eventpercent =
              (data?.data?.topic?.currentWeek / data?.data?.topic?.lastWeek) *
              100;
            setWeekConversationData({
              currentWeek: data?.data?.topic?.currentWeek || 0,
              percent: Math.round(Eventpercent) || 0,
              lastWeek: data?.data?.topic?.lastWeek || 0,
            });
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userData, dataFetched, LOGOUT_USER_SESSION, modulePermissions]);

  return (
    <Box className="managepro6ix-layout-page-content">
      {loader && <FullPageProgress />}

      <div className="managepro6ix-layout-page-content-header">
        <div className="managepro6ix-layout-page-content-header-left">
          <div className="managepro6ix-layout-page-content-header-heading">
            AI Dashboard
          </div>
        </div>
      </div>

      <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={4} md={3}>
          <Grid item xs={12}>
            <Card
              onClick={() => navigate("/ai/topics")}
              sx={{
                height: 140,
                marginTop: "10px",
                backgroundColor: "#927A64",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#927a64e6",
                },
              }}
            >
              <CardContent>
                <Typography
                  className="text-white text-start"
                  sx={{ fontSize: 12 }}
                  color="text.white"
                  gutterBottom
                >
                  Weekly Topics
                </Typography>
                <Typography
                  variant="h4"
                  className="text-white text-start"
                  component="div"
                >
                  {weekConversationData?.currentWeek}
                </Typography>
                {weekConversationData?.currentWeek > 0 ? (
                  <Box
                    variant="body2"
                    className="text-white d-flex items-center"
                    marginTop={1}
                  >
                    {weekConversationData?.percent >= 100 ? (
                      <>
                        <ArrowUpward width={20} height={20} />
                        {weekConversationData?.percent - 100}% Higher than last
                        week
                      </>
                    ) : (
                      <>
                        <ArrowDownwardIcon width={20} height={20} />
                        {100 - weekConversationData?.percent}% Lower than last
                        week
                      </>
                    )}
                  </Box>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={5} sx={{ height: 440 }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              height: 440,
              marginTop: "10px",
              backgroundColor: darkGray,
              overflow: "hidden",
              padding: "20px",
            }}
          >
            <Typography className="items-left d-flex justify-content-left text-white graphheader">
              Weekly
            </Typography>

            <BarChart
              xAxis={[
                { scaleType: "band", data: ["Current Week", "Last Week"] },
              ]}
              series={[
                {
                  data: [
                    weekConversationData.currentWeek,
                    weekConversationData.lastWeek,
                  ],
                  label: "Topics",
                  color: topicColor,
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{ height: 440, marginTop: { xs: "10px", md: 0 } }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              marginTop: "10px",
              backgroundColor: darkGray,
              overflow: "hidden",
              padding: "20px",
            }}
          >
            <Typography
              className="items-left d-flex justify-content-left text-white"
              marginBottom={2}
            >
              Today's Topics
            </Typography>
            <h4>{totalRows}</h4>
          </Grid>
          <TableContainer component={Paper}>
            <Table className="dashboard-event-table">
              {topicList?.length > 0 && (
                <caption>
                  <Link className="text-link" to="/ai/topics">
                    <ArrowDownwardIcon />
                    Show All Topics
                  </Link>
                </caption>
              )}
              <TableBody>
                {topicList.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {Moment(row?.createdAt).format("hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
