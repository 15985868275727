import CryptoJS from "crypto-js";
import { useCookies, Cookies } from "react-cookie";
import { useState, useEffect } from "react";
import { userLogout } from "../firebase";
import { useNavigate } from "react-router-dom";

const ENCRYPT_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const ENCRYPT_DATA = (data) => {
  const encrypted = CryptoJS.AES.encrypt(data, ENCRYPT_SECRET_KEY).toString();
  return encrypted;
};
const DECRYPT_DATA = (data) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(data, ENCRYPT_SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decrypted;
  } catch (error) {
    return;
  }
};

export const GET_USER_AUTH_TOKEN = () => {
  const cookies = new Cookies();
  const token = cookies.get("MPA6IX");
  return token ? `Bearer ${token}` : "";
};

export function SESSION_AUTH() {
  //const loginFetchedRef = useRef(true);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [loginSession, setLoginSession, removeLoginSession] = useCookies([
    "MPU6IX",
  ]);
  const LOGOUT_USER_SESSION = (user = "") => {
    userLogout(user);
    removeLoginSession("MPU6IX", { path: "/" });
    removeLoginSession("MPA6IX", { path: "/" });
    // loginFetchedRef.current = false;
    for (const cookie in cookies.getAll()) {
      cookies.remove(cookie, { path: "/" });
    }
    navigate(0);
  };

  const GET_USER_SESSION = () => {
    if (loginSession?.MPU6IX) {
      let data = DECRYPT_DATA(loginSession?.MPU6IX);
      try {
        data = data ? JSON.parse(data) : "";
        if (data?.userId) {
          return data;
        } else {
          LOGOUT_USER_SESSION();
          return "";
        }
      } catch (err) {
        LOGOUT_USER_SESSION();
        return "";
      }
    }
    return "";
  };

  const [userSession, setUserSession] = useState(() => GET_USER_SESSION());

  const SET_USER_SESSION = (user) => {
    let data = ENCRYPT_DATA(JSON.stringify(user));
    //loginFetchedRef.current = false;
    var expireDate = new Date();
    expireDate.setHours(0, 0, 0, 0);
    expireDate.setDate(expireDate.getDate() + 7);
    setLoginSession("MPU6IX", data, {
      sameSite: "strict",
      secure: true,
      expires: expireDate,
      path: "/",
    });
    setLoginSession("MPA6IX", user.authToken, {
      sameSite: "strict",
      secure: true,
      expires: expireDate,
      path: "/",
    });
  };

  const UPDATE_USER_SESSION = (data = false) => {
    if (data) {
      let user = GET_USER_SESSION();
      for (let key in data) {
        user[key] = data[key];
      }
      SET_USER_SESSION(user);
    }
  };

  useEffect(() => {
    // if (loginFetchedRef?.current) return;
    // loginFetchedRef.current = false;
    if (loginSession?.MPU6IX) {
      let data = DECRYPT_DATA(loginSession?.MPU6IX);
      try {
        setUserSession(data ? JSON.parse(data) : "");
      } catch (err) {
        setUserSession("");
      }
    } else {
      setUserSession("");
    }
  }, [loginSession]);

  return {
    userSession,
    SET_USER_SESSION,
    UPDATE_USER_SESSION,
    LOGOUT_USER_SESSION,
  };
}
