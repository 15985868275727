import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  useMediaQuery,
  useTheme,
  Box,
  Toolbar,
  IconButton,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import LayoutSidebar from "../LayoutSidebar";
import { SESSION_AUTH } from "../../helpers/auth";
import { USER_SERVICES } from "../../services";
import { MenuIcon } from "../../Icons";
import HeaderLogo from "./HeaderLogo";
import HeaderUsers from "./HeaderUsers";
import HeaderInvest from "./HeaderInvest";
import HeaderAttract from "./HeaderAttract";
import HeaderExpertise from "./HeaderExpertise";
import HeaderBottom from "./Bottom";
import "./index.scss";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const LayoutHeader = () => {
  const { userSession, UPDATE_USER_SESSION, LOGOUT_USER_SESSION } =
    SESSION_AUTH();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [userData, setUserData] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [userSession]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    USER_SERVICES.getUser({ userId: userData.userId })
      .then((data) => {
        if (data.code === 200) {
          UPDATE_USER_SESSION(data.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
      })
      .catch((error) => {});
  }, [userData, dataFetched, LOGOUT_USER_SESSION, UPDATE_USER_SESSION]);

  return (
    <>
      <AppBar
        className="managepro6ix-layout-header"
        position="fixed"
        open={openSidebar}
      >
        <Toolbar className="managepro6ix-layout-header-inner">
          {isMobile ? (
            <>
              {/* <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  setOpenSidebar(!openSidebar);
                }}
                className="managepro6ix-layout-header-menuBtn"
                sx={{ mr: 2 }}
              >
                <MenuIcon close={openSidebar} />
              </IconButton>
              <div className="managepro6ix-layout-header-lineV"></div> */}
              <HeaderLogo />
            </>
          ) : (
            <>
              <HeaderLogo />
              <HeaderInvest />
              <HeaderAttract />
              <HeaderExpertise />
            </>
          )}

          {/* {auth && ( */}
          <Box className="managepro6ix-layout-header-right">
            <div className="managepro6ix-layout-header-right-item">
              {userData && <HeaderUsers />}
            </div>
          </Box>
        </Toolbar>
        <HeaderBottom />
      </AppBar>
      {openSidebar && (
        <LayoutSidebar open={openSidebar} setOpen={setOpenSidebar} />
      )}
    </>
  );
};

export default LayoutHeader;
