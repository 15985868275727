import React from "react";
import Moment from "moment";
import { createCSVDownload } from "../../../helpers/utils";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material";

export function AiConversationCSV(props) {
  const { listData } = props;

  const exportToCsv = () => {
    let filename = "6ix-Ai-conversations.csv";
    let csvData = [];
    let csvHead = ["Search", "Created On"];
    csvData.push(csvHead);
    for (let i = 0; i < listData.length; i++) {
      let item = listData?.[i];
      let row = [
        item?.questions,
        item?.createdAt
          ? Moment(item?.createdAt).format("MMM DD, YYYY : hh:mm A")
          : "",
      ];
      csvData.push(row);
    }
    createCSVDownload(filename, csvData);
  };
  return (
    <>
      {listData?.length > 0 ? (
        <button
          type="button"
          onClick={() => {
            exportToCsv();
          }}
          className="managepro6ix-btn managepro6ix-btn-sm managepro6ix-btn-primary"
        >
          <FileDownloadIcon className="m-r-5" /> Export CSV
        </button>
      ) : null}
    </>
  );
}
