import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
} from "@mui/material/";
import { useParams } from "react-router-dom";
import { EVENTS_SERVICES } from "../../../../services";
import { ToastManager } from "../../../common/ToastContainer";
import FullPageProgress from "../../../common/FullPageProgress";
import { SESSION_AUTH } from "../../../../helpers/auth";

const EventUserProfile = () => {
  const [loader, setLoader] = useState(false);
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState("");
  const [profileData, setProfileData] = useState("");
  const dataFetched = useRef();
  const { userId } = useParams();

  useEffect(() => {
    if (userSession) {
      setUserData(userSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [userSession]);

  useEffect(() => {
    if (!userData || !userId || dataFetched?.current) return;
    dataFetched.current = true;
    setLoader(true);
    let parma = {};
    parma["userId"] = userData.userId;
    parma["id"] = userId;

    EVENTS_SERVICES.getUsersProfile(parma)
      .then((data) => {
        if (data.code === 200) {
          setProfileData(data?.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [userId, userData, dataFetched, LOGOUT_USER_SESSION]);
  return (
    <>
      {loader && <FullPageProgress />}
      {profileData ? (
        <TableContainer component={Paper}>
          <Table>
            <TableRow>
              <TableCell sx={{ width: 300 }}>Profile Pic</TableCell>
              <TableCell>
                {profileData?.profileImage ? (
                  <Avatar
                    style={{ width: "40px" }}
                    src={profileData?.profileImage}
                    alt=""
                  />
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{profileData?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{profileData?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone</TableCell>
              <TableCell>{profileData?.phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>
                {profileData?.lastLogin?.city},{" "}
                {profileData?.lastLogin?.country}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Job Title</TableCell>
              <TableCell>{profileData?.jobTitle}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Company</TableCell>
              <TableCell>{profileData?.work} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Investor Type</TableCell>
              <TableCell>
                {profileData?.investorType}{" "}
                {profileData?.investorType ? (
                  <>({profileData?.typicalSize})</>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Company Website</TableCell>
              <TableCell>{profileData?.companyWebsite}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bio</TableCell>
              <TableCell>{profileData?.bio}</TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
};

export default EventUserProfile;
