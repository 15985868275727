import React, { useRef, useEffect, useState } from "react";
import { USER_STATUS_LIST, PERMISSION_LIST } from "../../../helpers/constants";
import {
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { ToastManager } from "../../common/ToastContainer";
import FullPageProgress from "../../common/FullPageProgress";
import { ROLE_SERVICES } from "../../../services";
import { SESSION_AUTH } from "../../../helpers/auth";

export default function EditRole(props) {
  const { setOpen, open, update, role } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [permission, setPermission] = useState("");
  const dataFetchedRef = useRef(false);
  const [loader, setLoader] = useState(true);

  const onChangePermission = (checked, module, option) => {
    let permissionData = { ...permission };
    if (checked) {
      if (!permissionData) {
        permissionData = {};
      }
      if (!permissionData?.[module]) {
        permissionData[module] = {};
      }
      permissionData[module][option] = true;
    } else {
      delete permissionData?.[module]?.[option];
      if (
        permissionData?.[module] &&
        Object.keys(permissionData?.[module])?.length === 0
      ) {
        delete permissionData?.[module];
      }
    }

    setPermission(permissionData);
  };
  const onChangePermissionAll = (checked, module, objectOption) => {
    let permissionData = { ...permission };
    if (checked) {
      if (!permissionData) {
        permissionData = {};
      }
      if (!permissionData?.[module]) {
        permissionData[module] = {};
      }

      if (objectOption) {
        for (const option in objectOption) {
          permissionData[module][objectOption?.[option]?.value] = true;
        }
      }
    } else {
      delete permissionData?.[module];
    }
    setPermission(permissionData);
  };

  useEffect(() => {
    if (role?.id) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      setLoader(true);
      ROLE_SERVICES.editRole({ id: role?.id, userId: userSession?.userId })
        .then((data) => {
          if (data.code === 200) {
            setName(data?.data?.name);
            setStatus(data?.data?.status);
            if (data?.data?.permission) {
              setPermission(data?.data?.permission);
            }
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast(data?.message);
          }
        })
        .catch((error) => {
          ToastManager.addToast(error.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
    return () => null;
  }, [role, userSession, LOGOUT_USER_SESSION]);

  const handleClose = () => {
    setOpen(false);
  };

  const updateRole = async () => {
    if (checkFormFields()) {
      try {
        let parma = {
          userId: userSession?.userId,
          name,
          status,
          permission,
          id: role?.id,
        };
        const data = await ROLE_SERVICES.updatRole(parma);
        if (data.code === 200) {
          ToastManager.addToast(data.message);
          update();
          handleClose();
        } else if (data.code === 600) {
        } else {
          ToastManager.addToast(data?.message);
        }
      } catch (error) {
        ToastManager.addToast(error.message);
      }
    }
  };

  const checkFormFields = () => {
    if (!name) {
      ToastManager.addToast("Please fill  name");
      return false;
    } else if (!status) {
      ToastManager.addToast("Please choose role status");
      return false;
    } else if (!permission || Object.keys(permission)?.length === 0) {
      ToastManager.addToast("Please choose permission");
      return false;
    }

    return true;
  };

  const PermissionOption = (item) => {
    const moduleAll =
      permission?.[item.value] && item?.permission
        ? Object.keys(permission?.[item.value])?.length >=
          Object.keys(item?.permission)?.length
        : false;

    return (
      <div style={{ textAlign: "left", marginTop: "10px" }}>
        <FormControlLabel
          control={
            <Checkbox
              name={item?.value}
              checked={moduleAll}
              onChange={(e) => {
                onChangePermissionAll(
                  e.target.checked,
                  item?.value,
                  item?.permission
                );
              }}
            />
          }
          label={item?.name}
        />

        <Stack
          style={{ marginLeft: "50px" }}
          direction={{ xs: "column", sm: "row" }}
          useFlexGap
          spacing={{ xs: 1, sm: 3 }}
          justifyContent={{ xs: "start", sm: "center" }}
          alignItems="center"
        >
          {Object.keys(item.permission).map((option, index) => (
            <FormControlLabel
              key={index}
              sx={{ "& .MuiTypography-root": { fontSize: 14 } }}
              control={
                <Checkbox
                  name={item?.permission?.[option]?.value}
                  checked={permission?.[item?.value]?.[option]}
                  onChange={(e) => {
                    onChangePermission(
                      e.target.checked,
                      item?.value,
                      item?.permission?.[option]?.value
                    );
                  }}
                />
              }
              label={item?.permission?.[option]?.name}
            />
          ))}
        </Stack>
        <hr />
      </div>
    );
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog"
      >
        <DialogTitle className="text-center">Edit Role</DialogTitle>
        <DialogContent sx={{ width: "100%", maxWidth: "500px" }}>
          {loader && <FullPageProgress />}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} sx={{ marginTop: 2 }}>
              <TextField
                type="text"
                xs={6}
                label="Role name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sx={{ marginTop: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="status"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {Object.keys(USER_STATUS_LIST).map((item, index) => (
                    <MenuItem key={index} value={USER_STATUS_LIST[item].value}>
                      {USER_STATUS_LIST[item].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div>
            {Object.keys(PERMISSION_LIST).map((item, index) => (
              <PermissionOption {...PERMISSION_LIST[item]} key={index} />
            ))}
          </div>
        </DialogContent>
        <DialogActions className="custom-dialog-action">
          <button
            type="button"
            className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-primary"
            onClick={updateRole}
          >
            Update Role
          </button>{" "}
          <button
            onClick={() => {
              setOpen(false);
            }}
            className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-secondary"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
