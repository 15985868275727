import React from "react";
import { CookiesProvider } from "react-cookie";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./assets/managepro.scss";
import "./components/globalStyle.css";
import App from "./App";
import { STRIPE_PUBLISHABLE_KEY } from "./helpers/constants";

import { ThemeProvider, createTheme } from "@mui/material";
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e3c050",
    },
    // secondary: {
    //   main: "#8c8e95",
    // },
    // text: {
    //   main: "#fff",
    // },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("managepro-6ix-directory")
);

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Elements
        appearance={{
          theme: "stripe",
        }}
        stripe={stripePromise}
        options={{ themes: "stripe" }}
      >
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Elements>
    </CookiesProvider>
  </React.StrictMode>
);
