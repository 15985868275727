import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithUserEmailLinkVerify } from "../firebase";
import { SESSION_AUTH } from "../helpers/auth";
import FullPageProgress from "../components/common/FullPageProgress";

export function AuthPage() {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const { SET_USER_SESSION } = SESSION_AUTH();

  const VerifyLink = async (mode, actionCode, continueUrl) => {
    console.log(mode, "mode");
    console.log(continueUrl, "continueUrl");
    if (mode) {
      let email;
      let url;
      try {
        if (continueUrl) {
          url = new URL(continueUrl);
          email = url?.searchParams?.get("ref_l_email");
        }
      } catch (err) {
        email = "";
      }
      switch (mode) {
        case "signIn":
          let data = await signInWithUserEmailLinkVerify(email);
          if (data?.code === 200 && data?.data) {
            SET_USER_SESSION(data?.data);
          }
          if (url) {
            navigate(url?.pathname || "/");
          } else {
            navigate("/");
          }
          break;

        default:
          navigate("/");
          break;
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const mode = params.get("mode");
    // Get the one-time code from the query parameter.
    const actionCode = params.get("oobCode");
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = params.get("continueUrl");

    VerifyLink(mode, actionCode, continueUrl);
  });

  return (
    <>
      <FullPageProgress />
    </>
  );
}
