import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { styled } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  Drawer as MuiDrawer,
} from "@mui/material";

import {
  Dashboard as DashboardIcon,
  ExpandMore as ExpandMoreIcon,
  Event as EventIcon,
  SmartToy as SmartToyIcon,
  //AlignVerticalBottom as AlignVerticalBottomIcon,
  Engineering as EngineeringIcon,
  ExpandLess,
  Timeline,
} from "@mui/icons-material";

import { PERMISSION_LIST } from "../../helpers/constants";
import { SESSION_AUTH } from "../../helpers/auth";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  backgroundColor: "#1f1f1f",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const LayoutSidebar = (props) => {
  const { open, setOpen } = props;

  const { userSession } = SESSION_AUTH();
  const [modulePermissions, setModulePermissions] = useState();

  const [manageOpen, setManageOpen] = useState("");

  const openSubMenuList = (type) => {
    if (manageOpen === type) {
      setManageOpen("");
    } else {
      setOpen(true);
      setManageOpen(type);
    }
  };

  useEffect(() => {
    if (userSession) {
      setModulePermissions(userSession?.permission);
    } else {
      setModulePermissions("");
    }
    return () => null;
  }, [userSession]);

  return (
    <>
      <Drawer
        className="managepro6ix-layout-sidebar"
        variant="permanent"
        open={open}
      >
        <Divider />

        <List
          className="text-white h-screen"
          sx={{ backgroundColor: "#1f1f1f", height: "100vh" }}
        >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              component={NavLink}
              className="text-white"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              to="/"
            >
              <ListItemText
                primary={"Dashboard"}
                sx={{ opacity: open ? 1 : 0 }}
              />
              <ListItemIcon
                className="text-white"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DashboardIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              className="text-white"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                openSubMenuList("analytics");
              }}
            >
              <ListItemText
                primary={"Analytics"}
                sx={{ opacity: open ? 1 : 0 }}
              />
              <ListItemIcon
                className="text-white"
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Timeline />
              </ListItemIcon>
              {open ? (
                manageOpen === "analytics" ? (
                  <ExpandLess />
                ) : (
                  <ExpandMoreIcon />
                )
              ) : null}
            </ListItemButton>
            <Collapse
              in={manageOpen === "analytics" && open}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics"
                >
                  <ListItemText primary="Event Success Funnel" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/users"
                >
                  <ListItemText primary="New Users Signup" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-distribution"
                >
                  <ListItemText primary="Distribution of Events" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-host"
                >
                  <ListItemText primary="Events Created" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-live"
                >
                  <ListItemText primary="Events Live" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-participant"
                >
                  <ListItemText primary="Events Participants" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-invitation"
                >
                  <ListItemText primary="Events Invitation Sent" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-replay"
                >
                  <ListItemText primary="Events Replay" />
                </ListItemButton>

                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-replay-views"
                >
                  <ListItemText primary="Events Replay views" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-attendes"
                >
                  <ListItemText primary="Average Attendees per event" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-attendes-average"
                >
                  <ListItemText primary="Average Events per user" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to="/analytics/event-attendes-join"
                >
                  <ListItemText primary="Attendees Joining Timeline" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>

          {modulePermissions?.[PERMISSION_LIST?.events?.value]?.view && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                className="text-white"
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  openSubMenuList(PERMISSION_LIST?.events.value);
                }}
              >
                <ListItemText
                  primary={"6ix Events"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                <ListItemIcon
                  className="text-white"
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <EventIcon />
                </ListItemIcon>
                {open ? (
                  manageOpen === PERMISSION_LIST?.events.value ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMoreIcon />
                  )
                ) : null}
              </ListItemButton>
              <Collapse
                in={manageOpen === PERMISSION_LIST?.events.value && open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to="/events"
                  >
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to="/events/users"
                  >
                    <ListItemText primary="Users" />
                  </ListItemButton>
                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to="/events/organization"
                  >
                    <ListItemText primary="Organizations" />
                  </ListItemButton>
                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to="/events/list"
                  >
                    <ListItemText primary="Events" />
                  </ListItemButton>
                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to="/events/transactions"
                  >
                    <ListItemText primary="Transactions" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>
          )}

          {modulePermissions?.[PERMISSION_LIST?.ai?.value]?.view && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                className="text-white"
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  openSubMenuList(PERMISSION_LIST?.ai.value);
                }}
              >
                <ListItemText
                  primary={"6ix AI"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                <ListItemIcon
                  className="text-white"
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SmartToyIcon />
                </ListItemIcon>
                {open ? (
                  manageOpen === PERMISSION_LIST?.ai.value ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMoreIcon />
                  )
                ) : null}
              </ListItemButton>
              <Collapse
                in={manageOpen === PERMISSION_LIST?.ai.value && open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton component={NavLink} sx={{ pl: 4 }} to="/ai">
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>

                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to="/ai/topics"
                  >
                    <ListItemText primary="Topics" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>
          )}

          {/* {modulePermissions?.[PERMISSION_LIST?.promo?.value]?.view && (
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  className="text-white"
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    navigate("/promo");
                  }}
                >
                  <ListItemText
                    primary={"6ix Promo"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  <ListItemIcon
                    className="text-white"
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AlignVerticalBottomIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            )} */}
          {modulePermissions?.[PERMISSION_LIST?.manage.value]?.view && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                className="text-white"
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  openSubMenuList(PERMISSION_LIST?.manage.value);
                }}
              >
                <ListItemText
                  primary={"Manage"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                <ListItemIcon
                  className="text-white"
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <EngineeringIcon />
                </ListItemIcon>
                {open ? (
                  manageOpen === PERMISSION_LIST?.manage.value ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMoreIcon />
                  )
                ) : null}
              </ListItemButton>
              <Collapse
                in={manageOpen === PERMISSION_LIST?.manage.value && open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to="/manage/staff"
                  >
                    <ListItemText primary="Staff" />
                  </ListItemButton>
                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to="/manage/roles"
                  >
                    <ListItemText primary="Roles" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default LayoutSidebar;
