export const USER_STATUS_LIST = {
  active: {
    name: "Active",
    value: "active",
  },
  suspend: {
    name: "Suspend",
    value: "suspend",
  },
};

export const PERMISSION_LIST = {
  manage: {
    name: "Manage",
    value: "manage",
    permission: {
      view: { name: "View", value: "view" },
      add: { name: "Add", value: "add" },
      edit: { name: "Edit", value: "edit" },
      delete: { name: "Delete", value: "delete" },
    },
  },
  events: {
    name: "Events",
    value: "events",
    permission: {
      view: { name: "View", value: "view" },
      add: { name: "Add", value: "add" },
      edit: { name: "Edit", value: "edit" },
      delete: { name: "Delete", value: "delete" },
    },
  },
  ai: {
    name: "AI",
    value: "ai",
    permission: {
      view: { name: "View", value: "view" },
      add: { name: "Add", value: "add" },
      edit: { name: "Edit", value: "edit" },
      delete: { name: "Delete", value: "delete" },
    },
  },
  promo: {
    name: "Promo",
    value: "promo",
    permission: {
      view: { name: "View", value: "view" },
      add: { name: "Add", value: "add" },
      edit: { name: "Edit", value: "edit" },
      delete: { name: "Delete", value: "delete" },
    },
  },
};

export const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://manageproapi.6ix.com/api"
    : "http://localhost:3001/api";

export const PlatfomUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://matrix.6ix.com"
    : "http://localhost:3000";

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;

export const SUBSCRIPTION_STATUS_LIST = {
  1: {
    name: "Active",
    value: 1,
  },
  2: {
    name: "Expired",
    value: 2,
  },
  3: {
    name: "incomplete",
    value: 3,
  },
  4: {
    name: "past_due",
    value: 4,
  },
  5: {
    name: "unpaid",
    value: 5,
  },
};
export const SUBSCRIPTION_MANAGE = {
  manual: {
    name: "Manual",
    value: "manual",
  },
  stripe: {
    name: "Stripe",
    value: "stripe",
  },
};

export const EVENT_TYPE_LIST = {
  public: {
    name: "Public",
    value: "public",
  },
  private: {
    name: "Private",
    value: "private",
  },
};

export const INVESTOR_TYPE_LIST = {
  Retail: {
    value: "Retail",
    name: "Retail ($25K)",
    size: 25000,
  },
  Accredited: {
    value: "Accredited",
    name: "Accredited ($250K)",
    size: 250000,
  },
  Institutional: {
    value: "Institutional",
    name: "Institutional ($2500K)",
    size: 2500000,
  },
  Corporate: {
    value: "Corporate",
    name: "Corporate ($1000K)",
    size: 1000000,
  },
};

export const EVENT_STATUS_LIST = {
  published: {
    name: "Published",
    value: "published",
  },
  ended: {
    name: "Ended",
    value: "ended",
  },
};
