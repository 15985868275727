import React from "react";
import { Box } from "@mui/material";
import ManageRoles from "../components/Manage/Roles/ManageRoles";
import ManageStaff from "../components/Manage/Staff/ManageStaff";

export const ManageRolesPage = ({ module }) => {
  return (
    <Box className="managepro6ix-layout-page">
      <ManageRoles module={module} />
    </Box>
  );
};

export const ManageStaffPage = ({ module }) => {
  return (
    <Box className="managepro6ix-layout-page">
      <ManageStaff module={module} />
    </Box>
  );
};

export default ManageRolesPage;
