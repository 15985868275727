import React from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

export default function ShowSpeakersModal(props) {
  const { setOpen, open, data } = props;
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog custom-dialog-full"
    >
      <DialogTitle className="text-center">Speakers</DialogTitle>
      <DialogContent>
        {data?.map((speaker, key) => {
          return (
            <>
              <Typography id="modal-modal-title" className="popupheader">
                {speaker?.name}
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{
                  mt: 1,
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "14px",
                  gap: "5px",
                }}
              >
                <span>Email : {speaker?.email || "-"}</span>
              </Typography>
              <hr />
            </>
          );
        })}
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <button
          type="button"
          onClick={handleClose}
          className="custom-dialog-btn  managepro6ix-btn managepro6ix-btn-primary"
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
}
