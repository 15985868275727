import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GoogleIcon } from "../../../Icons";
import {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
} from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { ToastManager } from "../../common/ToastContainer";
import "./login.scss";
import FullPageProgress from "../../common/FullPageProgress";
import { SESSION_AUTH } from "../../../helpers/auth";
import { isValidEmail } from "../../../helpers/utils";

export default function Login(props) {
  const { userSession, SET_USER_SESSION } = SESSION_AUTH();
  const [, loading, error] = useAuthState(auth);

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [loginLinkMSG, setLoginLinkMSG] = useState("");

  useEffect(() => {
    if (userSession) {
      navigate("/");
    }
  }, [userSession, navigate]);

  const loginWithGoogle = async () => {
    setLoader(true);
    const data = await signInWithGoogle();
    if (data?.code === 200 && data?.data) {
      SET_USER_SESSION(data?.data);
    }
    setLoader(false);
  };

  const loginWIthEmail = async () => {
    if (email && isValidEmail(email)) {
      setLoader(true);
      const data = await signInWithUserEmailLink(email);
      if (data) {
        setLoginLinkMSG(data);
      }
      setLoader(false);
    } else {
      ToastManager.addToast("Enter your email address");
    }
  };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);

  return (
    <>
      {loading || loader ? <FullPageProgress /> : null}

      <Box
        open={true}
        className="managepro6ix-loginPopup"
        sx={{ sm: 8, xs: 11, md: 6 }}
      >
        <div className="managepro6ix-loginPopup-logo">
          <img src="/assets/img/6ix-managepro-logo.png" alt="logo" />
        </div>
        <Box className="managepro6ix-loginPopup-inner">
          <h1 className="managepro6ix-loginPopup-heading">Sign up or log in</h1>
          <div className="managepro6ix-loginPopup-text">
            If you’ve already registered, this will log you in
          </div>
          <button
            type="button"
            expand="full"
            className="managepro6ix-btn managepro6ix-btn-secondary m-t-20 managepro6ix-btn-large"
            onClick={loginWithGoogle}
          >
            <span className="m-r-20">
              <GoogleIcon />
            </span>
            CONTINUE WITH GOOGLE
          </button>
          <div className="managepro6ix-loginPopup-or">
            <span className="managepro6ix-loginPopup-or-text">or</span>
          </div>
          {loginLinkMSG ? (
            <>
              <div className="managepro6ix-loginPopup-email-msg">
                {loginLinkMSG}
              </div>
              <div
                className="managepro6ix-loginPopup-email-link "
                onClick={() => {
                  setLoginLinkMSG("");
                }}
              >
                Did not get the link?
              </div>
            </>
          ) : (
            <>
              <TextField
                className="managepro6ix-loginPopup-input"
                xs={6}
                label="Your Email"
                id="outlined-size-small"
                size="big"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    if (!event.shiftKey) {
                      loginWIthEmail();
                    }
                  }
                }}
              />
              <button
                type="button"
                expand="full"
                className="managepro6ix-btn managepro6ix-btn-primary managepro6ix-btn-large"
                text="14"
                onClick={loginWIthEmail}
              >
                CONTINUE WITH EMAIL
              </button>{" "}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
