import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { List, ListItemButton, ListItemText, Menu } from "@mui/material";
import { AngleIcon } from "../../../Icons";

const HeaderAnalytics = () => {
  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const headerMenuOpenReq = (event) => {
    setHeaderMenuEl(event.currentTarget);
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  return (
    <React.Fragment>
      <div
        className={`managepro6ix-layout-header-bottom-menu-link ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-analytics-menu-button"
        aria-controls={headerMenuOpen ? "header-analytics-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        Analytics
        {headerMenuOpen ? <AngleIcon up /> : <AngleIcon small />}
      </div>

      <Menu
        id="header-analytics-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-analytics-menu-button",
        }}
        className="managepro6ix-layout-header-bottom-menu-link-dropdown managepro6ix-layout-header-bottom-menu-link-dropdown-users"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          component="nav"
          className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list"
        >
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Event Success Funnel"
            />
          </ListItemButton>

          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/users"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="New Users Signup"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-distribution"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Distribution of Events"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-host"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Events Created"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-live"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Events Live"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-participant"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Events Participants"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-invitation"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Events Invitation Sent"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-replay"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Events Replay"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-replay-views"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Events Replay Views"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-attendes"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Average Attendees Per Event"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-attendes-average"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Average Events Per User"
            />
          </ListItemButton>
          <ListItemButton
            className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item"
            component={NavLink}
            to="/analytics/event-attendes-join"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="managepro6ix-layout-header-bottom-menu-link-dropdown-users-list-item-text"
              primary="Attendees Joining Timeline"
            />
          </ListItemButton>
        </List>
      </Menu>
    </React.Fragment>
  );
};

export default HeaderAnalytics;
