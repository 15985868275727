import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";
import { Box, TablePagination } from "@mui/material";

import DataTable from "../../Table/DataTable";
import Moment from "moment";

import { SESSION_AUTH } from "../../../helpers/auth";
import { getModuleAllPermissions } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "../../common/ToastContainer";
import { EVENTS_SERVICES } from "../../../services";
import FullPageProgress from "../../common/FullPageProgress";

export default function EventOrgazinationTransition(props) {
  const { accountId } = useParams();
  const { module } = props;
  const { userSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState("");
  const [loader, setLoader] = useState(false);
  const [modulePermissions, setModulePermissions] = useState();
  const [orgList, setOrgList] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const defaultFilter = {
    page: 0,
    limit: 10,
    name: "",
    email: "",
    phoneNumber: "",
    activeStatus: "",
  };
  const [filter, setFilter] = useState(defaultFilter);

  const handleChangePage = (event, newPage) => {
    setFilter((old) => ({
      ...old,
      page: newPage,
    }));
    setDataFetched(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setFilter((old) => ({
      ...old,
      limit: event.target.value,
      page: 0,
    }));
    setDataFetched(false);
  };

  useEffect(() => {
    if (userSession) {
      setModulePermissions(getModuleAllPermissions(userSession, module));
      setUserData(userSession);
    } else {
      setModulePermissions("");
      setUserData("");
    }
    return () => null;
  }, [userSession, module]);

  useEffect(() => {
    if (!userData || dataFetched || !modulePermissions?.view) return;

    setLoader(true);
    setDataFetched(true);

    let parma = { ...filter };
    parma["userId"] = userData.userId;
    parma["accountId"] = accountId;
    EVENTS_SERVICES.getTransitionsList(parma)
      .then((data) => {
        setOrgList([]);
        if (data.code === 200) {
          setOrgName(data?.data?.name);
          setOrgList(data?.data?.list || []);
          setTotalRows(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          ToastManager.addToast(data?.message);
        } else {
          ToastManager.addToast(data?.message);
        }
      })
      .catch((error) => {
        ToastManager.addToast(error.message);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [
    userData,
    dataFetched,
    LOGOUT_USER_SESSION,
    modulePermissions,
    filter,
    accountId,
  ]);

  useEffect(() => {
    if (userData && !modulePermissions?.view) {
      return navigate("/");
    }
  }, [modulePermissions, userData, navigate]);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    { name: "sno", label: "S.No." },

    {
      name: "amount",
      label: "Amount",
      options: {
        customBodyRender: (item) => {
          return (
            <>
              <NumericFormat
                value={item?.amount || 0}
                displayType="text"
                thousandSeparator={true}
                prefix={getSymbolFromCurrency(item?.currency)}
                renderText={(value, props) => <span {...props}>{value}</span>}
              />
            </>
          );
        },
      },
    },
    { name: "reason", label: "Reason" },
    { name: "createdAt", label: "Created" },
    {
      name: "invoice",
      label: "Invoice",
      options: {
        customBodyRender: (item) => {
          return item?.id ? (
            <>
              <a
                className="text-link"
                target="_blank"
                href={item?.url}
                rel="noreferrer"
              >
                {item?.id}
              </a>
            </>
          ) : null;
        },
      },
    },
    { name: "transactionId", label: "Transaction Id" },
    { name: "manage", label: "Manage" },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (description) => {
          return description?.map((item, keyd) => (
            <div key={keyd}>{item?.description}</div>
          ));
        },
      },
    },
  ];
  const customOption = {
    pagination: false,
    sort: false,
  };

  return (
    <>
      {loader && <FullPageProgress />}
      <Box className="managepro6ix-layout-page-content">
        <div className="managepro6ix-layout-page-content-header">
          <div className="managepro6ix-layout-page-content-header-left">
            <div className="managepro6ix-layout-page-content-header-heading">
              6ix Transactions : {orgName}
            </div>
          </div>
        </div>

        <div className="managepro6ix-table-center">
          <DataTable
            customOption={customOption}
            columns={columns}
            data={orgList.map((item, key) => {
              return {
                sno: filter?.page * filter?.limit + key + 1,
                amount: { amount: item?.amount, currency: item?.currency },
                invoice: {
                  id: item?.invoice_id,
                  url: item?.hosted_invoice_url,
                },
                transactionId: item?.transactionId,
                reason: item?.reason,
                manage: item?.manage,
                description: item?.lines,
                createdAt: Moment(item?.createdAt).format(
                  "MMM DD, YYYY : hh:mm A"
                ),
                id: item?._id,
              };
            })}
          />
        </div>
        <TablePagination
          component="div"
          count={totalRows}
          page={filter.page}
          onPageChange={handleChangePage}
          rowsPerPage={filter.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </Box>
    </>
  );
}
