import React from "react";
import { Box } from "@mui/material";
import Dashboard from "../components/Dashoard/Dashboard";

export const DashboardPage = () => {
  return (
    <Box className="managepro6ix-layout-page">
      <Dashboard />
    </Box>
  );
};

export default DashboardPage;
